import './Mlflow.less';
import React, { useState } from 'react';
import { InfoCircleOutlined, AimOutlined } from '@ant-design/icons';
import { Modal, notification, Switch, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import MlflowModal from './MlflowModal';
import { useMutateMLflowState } from 'hooks/cluster';
import { Instance, ServerConfiguration, Service } from 'types/cluster';
import { getClusterStatus } from 'utils/cluster';

interface ClusterConnectMlflowEditProps {
  instance: Instance;
  serverConfigurations: ServerConfiguration[];
  services: Service[];
}

function MLflow({
  instance,
  serverConfigurations,
  services,
}: ClusterConnectMlflowEditProps) {
  const { mutateAsync: mutateMLflowState, isLoading } = useMutateMLflowState({
    instanceID: instance.id,
  });
  async function handleChange(enable: boolean) {
    if (enable) {
      const { data } = await mutateMLflowState(true);
      notification.success({
        message: 'Success',
        description: data.message,
      });
    } else {
      Modal.confirm({
        title: 'Disconnect MLflow!',
        content:
          'Disconnecting will lead loss of any current or upcoming query request.',
        okText: 'Disconnect',
        centered: true,
        width: 620,
        async onOk() {
          const { data } = await mutateMLflowState(false);
          notification.success({
            message: 'Success',
            description: data.message,
          });
        },
      });
    }
  }

  const canUpdate = instance.clusterPolicy.clusterPolicy.includes('update');
  const status = getClusterStatus(instance, services[0]);

  const enable = status === 'running';
  const [showModal, setShowModal] = useState(false);
  let textJson = JSON.parse(serverConfigurations[0]?.text);

  return (
    <div className="ClusterEdit ClusterConnectBiToolsEdit">
      <div className="ClusterEdit__icon">
        <AimOutlined />
      </div>
      <div className="ClusterEdit__content ClusterConnectBiToolsEdit__content">
        <div className="ClusterConnectBiToolsEdit__main">
          <h3 className="ClusterEdit__title">
            <FormattedMessage id="clusterConfiguration.titles.mlFlow" />
          </h3>
          <div className="ClusterConnectBiToolsEdit__body">
            <p className="ClusterConnectBiToolsEdit__hint">
              {instance.mlflowEnabled ? (
                <FormattedMessage id="clusterConfiguration.mlFlow.connectToMLflow" />
              ) : (
                <FormattedMessage id="clusterConfiguration.mlFlow.enableConnectMLTools" />
              )}
            </p>

            {instance.mlflowEnabled ? (
              <div className="ClusterConnectBiToolsEdit__info-wrapper">
                {serverConfigurations.map(config => {
                  return (
                    <div
                      key={config.name}
                      className="ClusterConnectBiToolsEdit__info-box"
                    >
                      <div className="ClusterConnectBiToolsEdit__info-box-label">
                        {config.name}{' '}
                        <Tooltip
                          placement="top"
                          title={config.description}
                          overlayClassName="full-width-tooltip"
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                      <div className="ClusterConnectBiToolsEdit__info-box-content">
                        <a
                          href={textJson.publicUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {textJson.publicUrl}
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
            {instance.mlflowEnabled && (
              <p className="delta-sharing__hint">
                <br></br>
                <button
                  className="link"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <>Integration Sample</>
                </button>
              </p>
            )}
          </div>
        </div>
        <div className="ClusterConnectBiToolsEdit__switch-button">
          {canUpdate && (
            <Tooltip
              title={
                status !== 'running'
                  ? 'Your cluster must be connected to apply this change'
                  : ''
              }
            >
              <Switch
                onChange={handleChange}
                loading={isLoading}
                checked={instance.mlflowEnabled}
                disabled={!enable}
              />
            </Tooltip>
          )}
        </div>
      </div>
      <div></div>
      <Modal
        visible={showModal}
        title={'MLflow Configurations'}
        onCancel={() => setShowModal(false)}
        width={800}
        centered
        footer={null}
      >
        <MlflowModal
          instance={instance}
          url={`import os \nimport mlflow \nmlflow.set_tracking_uri('${textJson.privateUrl}')`} />
      </Modal>
    </div>
  );
}

export default MLflow;
