export const parseDateFromUTCToString = (value: string) => {
  let date = new Date(value);
  return (
    date.getFullYear().toString().slice(-2) +
    '/' +
    (date.getMonth() + 1) +
    '/' +
    date.getDate() +
    ' ' +
    date.getHours() +
    ':' +
    date.getMinutes()
  );
};
