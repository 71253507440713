import React from 'react';
import './DataAgentVersionCellRenderer.less';
import { Tooltip } from 'antd';
import { DataAgentAndControllerVersionResponse } from 'types/dataAgent.types';
import useDataAgentHelper from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterDataAgentEdit/useDataAgentHelper';

type DataAgentVersionCellRendererProps = {
  dataAgentAndControllerVersionResponse: DataAgentAndControllerVersionResponse;
};
function DataAgentVersionCellRenderer({
  dataAgentAndControllerVersionResponse,
}: DataAgentVersionCellRendererProps) {
  const { dataAgentVersionMap } = useDataAgentHelper();

  if (!dataAgentAndControllerVersionResponse) {
    return null;
  }

  const { version, compatibility } = dataAgentAndControllerVersionResponse;

  const tooltipValue = dataAgentVersionMap.get(compatibility)?.tooltip || null;
  const icon = dataAgentVersionMap.get(compatibility)?.icon || null;

  return (
    <article className="data-agent-connections-table__version-cell">
      <span>{version}</span>
      <Tooltip title={tooltipValue} placement="right">
        {icon}
      </Tooltip>
    </article>
  );
}

export default DataAgentVersionCellRenderer;
