import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import './ClusterForm.less';
import { InfoCircleFilled } from '@ant-design/icons';
import { Input, Button, InputNumber, Row, Col, Select, Form, Spin } from 'antd';
import { Rule } from 'antd/lib/form';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import InstallationField from '../InstallationField/InstallationField';
import IncortaVersionField from '../IncortaVersionField/IncortaVersionField';
import IncortaClusterRegionField from '../IncortaClusterRegionField/IncortaClusterRegionField';
import IncortaPlatformField from '../IncortaPlatformField/IncortaPlatformField';
import ClusterTypeField from '../ClusterTypeField/ClusterTypeField';
import DataAppFormField from '../DataAppField/DataAppField';
import ClusterOptimization from '../ClusterOptimization/ClusterOptimization';
import {
  Image,
  OptimizationLevel,
  Region,
  ClusterFormData,
  SizeResultItem,
} from 'types/cluster';
import { clusterNameRules, envNameRules } from 'utils/cluster';
import { InstancePlatform } from 'utils/platforms';
import {
  useAzureClusterPipelineState,
  useGetIncortaImages,
  useOperators,
  useSuspendTimes,
  useClusterSizeTypes,
} from 'hooks/cluster';
import { SessionContext } from 'auth/SessionProvider';
import useToggleFeature, { TOGGLE_FEATURE_KEYS } from 'hooks/useToggleFeature';
import { LocationContext } from 'contexts/LastLocation';

export const clusterFormFieldNames = {
  csize: 'csize',
  idleTime: 'idleTime',
  image: 'image',
  name: 'name',
  platform: 'platform',
  region: 'region',
  tenantID: 'tenantID',
  installation: 'installation',
  dsize: 'dsize',
  optimizationLevel: 'optimizationLevel',
  blueprintId: 'blueprintId',
} as const;

interface ClusterFormProps {
  onSubmit: (values: ClusterFormData) => void;
  loading: boolean;
  cluster: {
    name: string;
    csize: SizeResultItem;
    dsize?: number;
    image?: string;
    tenantID?: number;
    installation?: string;
    alive?: boolean;
    idleTime?: number;
  };
}
function ClusterForm({ onSubmit, loading, cluster }: ClusterFormProps) {
  const [form] = Form.useForm();
  const [currentPlatform, setCurrentPlatform] = useState(InstancePlatform.GC);
  const [
    isAzureReadyForValidationTrigger,
    setIsAzureReadyForValidationTrigger,
  ] = useState(false);

  const { validateFields, getFieldsValue, getFieldValue, setFieldsValue } =
    form;
  const history = useHistory();
  const intl = useIntl();

  const { data: operatorsResult } = useOperators();
  const regionsObj = operatorsResult?.data.regions ?? [];

  const currentRegionAliasValue: string | undefined = form.getFieldValue(
    clusterFormFieldNames.region,
  );
  const currentRegionClusterCode = regionsObj.find(
    region =>
      region.regionAlias === currentRegionAliasValue && region.isAvailable,
  )?.cluster_code!;

  const [
    currentSelectedRegionClusterCode,
    setCurrentSelectedRegionClusterCode,
  ] = useState<string>(currentRegionClusterCode);

  const { data: clusterSizesResult, isLoading: isClusterSizesResultLoading } =
    useClusterSizeTypes({
      currentPlatform,
      clusterCode: currentSelectedRegionClusterCode || currentRegionClusterCode,
    });

  const clusterSizesData = clusterSizesResult?.data;
  const sizes = useMemo(
    () => clusterSizesData?.sizes ?? [],
    [clusterSizesData],
  );

  const sizesRef = useRef(sizes);

  useEffect(() => {
    if (sizes?.length && !_.isEqual(sizes, sizesRef.current)) {
      const fieldsValues = getFieldsValue();

      // set the csize with 1st size
      setFieldsValue({
        ...fieldsValues,
        csize: sizes[0],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizes]);

  const { lastLocation } = useContext(LocationContext);
  const { user } = useContext(SessionContext);
  const configurations = user?.configurations;

  const { data: suspendTimes } = useSuspendTimes();
  const suspendData = suspendTimes?.data.availableIdleTimes;

  const { data: images, isLoading: isGetIncortaImagesLoading } =
    useGetIncortaImages();
  const image =
    getFieldValue(clusterFormFieldNames.image) ||
    (cluster?.image ?? images?.[0]?.image) ||
    null;
  const [, setImage] = useState(null);

  async function handleSubmit(formData: ClusterFormData) {
    try {
      const values = await validateFields();
      let regionObject: Region = {} as Region;
      for (const r in regionsObj) {
        if (regionsObj[r].regionAlias === values.region) {
          if (currentPlatform === InstancePlatform.AZURE) {
            if (regionsObj[r].userId === user.uuid) {
              regionObject = regionsObj[r];
              break;
            }
          } else {
            regionObject = regionsObj[r];
            break;
          }
        }
      }

      values.analyticsSizeID = formData.csize.id;
      values.loaderSizeID = formData.csize.id;

      const currentSizeOrder = clusterSizesResult!.data.allSizes.find(
        size => size.id === formData.csize.id,
      )!.order;

      const currentSizeSmallerId =
        clusterSizesResult!.data.allSizes.find(
          size => size.order === currentSizeOrder - 1,
        )?.id || formData.csize.id;

      if (values.optimizationLevel === OptimizationLevel.Analytics) {
        values.loaderSizeID = currentSizeSmallerId;
      } else if (values.optimizationLevel === OptimizationLevel.Loader) {
        values.analyticsSizeID = currentSizeSmallerId;
      }
      delete values.csize;

      delete values.optimizationLevel;
      values.zone = regionObject?.zone;
      values.k8sClusterCode = regionObject?.cluster_code;
      values.region = regionObject?.region;
      values.appId = regionObject?.app_id ?? '1';

      if (
        [InstancePlatform.AZURE, InstancePlatform.AZURE_SAAS].includes(
          currentPlatform,
        )
      ) {
        delete values.image;
      }

      onSubmit(values);
    } catch {}
  }

  function resetFields() {
    const fieldsValues = getFieldsValue();

    const firstRegionAlias = regionsObj.find((region: Region) =>
      currentPlatform === InstancePlatform.AZURE
        ? region.platform === currentPlatform && region.userId === user.uuid
        : region.platform === currentPlatform,
    )?.regionAlias;
    const selectedImage =
      currentPlatform === InstancePlatform.AZURE
        ? azureClusterPipelineData?.pipelineStatus?.incortaImage
        : image;
    setFieldsValue({
      ...fieldsValues,
      // csize:
      //   getClusterTypeValue(azureClusterPipelineData?.pipelineStatus?.plan!) ||
      //   csizeDefaultSize,
      idleTime: 1,
      region: firstRegionAlias,
      image: selectedImage,
    });
  }

  const {
    data: azureClusterPipelineData,
    isLoading: azureClusterPipelineIsLoading,
  } = useAzureClusterPipelineState({
    email: user.email,
    userID: user.uuid,
    enabled: !!(user.pipelineStatus && user.uuid && user.email),
  });

  const { isToggleFeatureEnabled: azureSupportEnabled } = useToggleFeature({
    user,
    featureKey: TOGGLE_FEATURE_KEYS.azureSupport,
  });

  const { isToggleFeatureEnabled: azureSaaSSupportEnabled } = useToggleFeature({
    user,
    featureKey: TOGGLE_FEATURE_KEYS.azureSaaSSupport,
  });

  async function validateNameField() {
    try {
      await validateFields([clusterFormFieldNames.name]);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (isAzureReadyForValidationTrigger) {
      // AZ
      resetFields();
      validateNameField();
    } else if (currentPlatform === InstancePlatform.AZURE_SAAS) {
      // AZ-SAAS
      const fieldsValues = getFieldsValue();
      const firstRegionAlias = regionsObj.find(
        (region: Region) => region.platform === currentPlatform,
      )?.regionAlias;
      const selectedImage = images?.[0]?.image;
      setFieldsValue({
        ...fieldsValues,
        region: firstRegionAlias,
        ...(selectedImage ? { image: selectedImage } : {}),
      });
      validateNameField();
    } else {
      // GC
      const fieldsValues = getFieldsValue();
      const firstRegionAlias = regionsObj.find(
        (region: Region) => region.platform === currentPlatform,
      )?.regionAlias;
      setFieldsValue({
        ...fieldsValues,
        region: firstRegionAlias,
        image,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlatform]);

  useEffect(() => {
    if (azureSupportEnabled) {
      setIsAzureReadyForValidationTrigger(true);
    }
  }, [azureSupportEnabled]);

  useEffect(() => {
    const regionFieldValue = getFieldValue('region');
    if (!regionFieldValue) {
      resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionsObj]);

  useEffect(() => {
    const imageFieldValue = getFieldValue('image');
    if (!imageFieldValue && images?.length) {
      resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  function availableSaaSOperator() {
    const regionsObj = operatorsResult?.data.regions ?? [];
    return regionsObj.filter(
      (region: Region) => region.platform === InstancePlatform.AZURE_SAAS,
    );
  }

  function isSaaSEnabled() {
    const availableSaaSOperators = availableSaaSOperator();
    return availableSaaSOperators.length > 0 && azureSaaSSupportEnabled;
  }
  const enabledSaaS = isSaaSEnabled();

  return (
    <Form
      form={form}
      layout="vertical"
      className="cluster-form"
      onFinish={handleSubmit}
      onValuesChange={(changedValues, allValues) => {
        if (changedValues.region) {
          const currentSelectedClusterCode = regionsObj.find(
            region =>
              region.regionAlias === changedValues.region && region.isAvailable,
          )?.cluster_code!;
          setCurrentSelectedRegionClusterCode(currentSelectedClusterCode);
        }
      }}
    >
      <Row justify="center">
        <Col xs={24} lg={12} xxl={10}>
          {(azureSupportEnabled || enabledSaaS) && (
            <IncortaPlatformField
              setSelectedPlatform={setCurrentPlatform}
              azureClusterPipelineData={azureClusterPipelineData}
              azureSupportEnabled={azureSupportEnabled}
              azureSaaSEnabled={enabledSaaS}
            />
          )}
          {user && (
            <IncortaClusterRegionField
              isTrial={user?.role?.id === 1}
              regions={regionsObj}
              currentPlatform={currentPlatform}
            />
          )}
          <Form.Item
            name={clusterFormFieldNames.name}
            initialValue={cluster.name}
            rules={
              [InstancePlatform.AZURE, InstancePlatform.AZURE_SAAS].includes(
                currentPlatform,
              )
                ? (envNameRules as Rule[])
                : (clusterNameRules as Rule[])
            }
            className="cluster-input-with-hint"
            label={<FormattedMessage id="clusterForm.clusterName" />}
            extra={
              <>
                <InfoCircleFilled />{' '}
                <FormattedMessage
                  id={
                    [
                      InstancePlatform.AZURE,
                      InstancePlatform.AZURE_SAAS,
                    ].includes(currentPlatform)
                      ? 'clusterForm.clusterNameHint.azure'
                      : 'clusterForm.clusterNameHint.gc'
                  }
                />
              </>
            }
          >
            <Input
              placeholder={intl.formatMessage({
                id: [
                  InstancePlatform.AZURE,
                  InstancePlatform.AZURE_SAAS,
                ].includes(currentPlatform)
                  ? 'consentFlow.envNamePlaceholder'
                  : 'clusterForm.clusterNamePlaceholder',
              })}
            />
          </Form.Item>
          {configurations?.showInstanceSize?.enabled &&
            (!!(
              clusterSizesData &&
              (currentSelectedRegionClusterCode || currentRegionClusterCode) &&
              !isClusterSizesResultLoading
            ) ? (
              <ClusterTypeField
                clusterSizesData={clusterSizesData}
                currentPlatform={currentPlatform}
                azureClusterSize={
                  azureClusterPipelineData?.pipelineStatus?.plan
                }
              />
            ) : (
              <Spin>
                Fetching Available Sizes based on the selected region
                <div className="size-fields__skeleton" />
              </Spin>
            ))}
          {configurations?.showClusterOptimization?.enabled && (
            <ClusterOptimization />
          )}
          {configurations?.showLatestRelease?.enabled && (
            <IncortaVersionField
              isDisabled={[
                InstancePlatform.AZURE,
                InstancePlatform.AZURE_SAAS,
              ].includes(currentPlatform)}
              isLoading={
                currentPlatform === InstancePlatform.AZURE
                  ? azureClusterPipelineIsLoading
                  : isGetIncortaImagesLoading
              }
              images={
                currentPlatform === InstancePlatform.AZURE
                  ? ([
                      {
                        image:
                          azureClusterPipelineData?.pipelineStatus
                            ?.incortaImage,
                      },
                    ] as Image[])
                  : images || []
              }
              image={
                currentPlatform === InstancePlatform.AZURE
                  ? azureClusterPipelineData?.pipelineStatus?.incortaImage
                  : image
              }
              setImage={setImage}
            />
          )}
          {configurations?.showTenants?.enabled && (
            <>
              <DataAppFormField currentVersion={image} />
            </>
          )}
          {configurations?.showInstallation?.enabled && (
            <InstallationField clusterInstallation={cluster.installation} />
          )}
          {configurations?.showDiskSize?.enabled && (
            <Form.Item
              name={clusterFormFieldNames.dsize}
              initialValue={cluster.dsize}
              label={<FormattedMessage id="clusterForm.clusterDiskSizeLabel" />}
              className="cluster-form__disk-size-input"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="clusterForm.clusterDiskSizeRequired" />
                  ),
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={configurations?.showDiskSize?.min}
                max={configurations?.showDiskSize?.max}
                placeholder={intl.formatMessage({
                  id: 'clusterForm.clusterDiskSizePlaceholder',
                })}
              />
              <span className="GB">
                <FormattedMessage id="clusterForm.clusterSizeUnitGB" />
              </span>
            </Form.Item>
          )}
          {configurations?.showAlwaysAlive?.enabled && (
            <Form.Item
              name={clusterFormFieldNames.idleTime}
              initialValue={cluster.idleTime}
              label={'Auto Suspend'}
            >
              <Select loading={!suspendData}>
                {suspendData?.map(({ key, display }) => {
                  return (
                    <Select.Option key={key} value={key}>
                      {display}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            className="cluster-form__button-item"
            style={{ marginBottom: 6 }}
          >
            <Button block type="primary" htmlType="submit" loading={loading}>
              <FormattedMessage id="clusterForm.clusterCreateButton" />
            </Button>
          </Form.Item>
          <Form.Item className="cluster-form__button-item cluster-form__button-item--no-margin">
            <Link to="/clusters">
              <Button
                block
                htmlType="button"
                onClick={() => {
                  if (lastLocation) {
                    history.push(lastLocation);
                  } else {
                    history.push('/');
                  }
                }}
              >
                <FormattedMessage id="clusterForm.clusteCancelButton" />
              </Button>
            </Link>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default ClusterForm;
