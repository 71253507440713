import React, { useEffect, useState } from 'react';
import './DebouncedInput.less';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';

/**
 * A debounced input react component
 * @returns antd Input
 */
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  placeholder,
  prefix,
  ...props
}: InputProps & {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
  placeholder?: string;
  prefix?: any;
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Input
      value={value}
      onChange={e => setValue(e.target.value)}
      className="debounced-input"
      {...(placeholder && { placeholder })}
      {...(prefix && { prefix })}
      size="large"
      {...props}
    />
  );
}

export default DebouncedInput;
