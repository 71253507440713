import './ClusterSizeEdit.less';

import React, { useMemo, useState } from 'react';
import { Button, notification, Spin, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useClusterSizeTypes, useMutateCluster } from 'hooks/cluster';
import usePlatformActionsSupport, {
  PLATFORM_ACTIONS_KEYS,
} from 'hooks/usePlatformActionsSupport';
import { Instance, Service, SizeResultItem } from 'types/cluster';
import {
  getClusterStatus,
  getClusterTypeIcons,
  getClusterSize,
} from 'utils/cluster';
import ClusterTypeFieldSlider from 'components/ClusterCreate/ClusterTypeField/ClusterTypeFieldSlider/ClusterTypeFieldSlider';
import { UserData } from 'types/user';
import { InstancePlatform } from 'utils/platforms';

interface ClusterSizeEditProps {
  instance: Instance;
  services: Service[];
  user: UserData;
}
function ClusterSizeEdit({ instance, services, user }: ClusterSizeEditProps) {
  const intl = useIntl();
  const currentSize =
    instance.loaderSize.order > instance.analyticsSize.order
      ? instance.loaderSize
      : instance.analyticsSize;
  const currentSizeId = currentSize.id;
  const [size, setSize] = useState(currentSize);
  const { data: clusterSizesResult } = useClusterSizeTypes({
    currentPlatform: instance.platform as InstancePlatform,
    clusterCode: instance.k8sClusterCode,
  });
  const editableSizes = clusterSizesResult?.data.sizes;
  const allSizes = clusterSizesResult?.data.allSizes;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allAvailableSizes = useMemo(() => getAllAvailableSizes(), [allSizes]);

  const { mutateAsync: mutateCluster, isLoading } = useMutateCluster({
    clusterName: instance.name,
  });

  function getAllAvailableSizes() {
    if (!clusterSizesResult?.data?.allSizes) {
      return [];
    }

    const currentSizeInEditableSizes = editableSizes?.find(
      size => size.id === currentSizeId,
    );

    if (!currentSizeInEditableSizes) {
      return [...(editableSizes || []), instance.analyticsSize];
    } else {
      return editableSizes;
    }
  }

  const canUpdate = instance.clusterPolicy.clusterPolicy.includes('update');

  async function handleSubmit() {
    const newSizeId =
      editableSizes?.find(editableSize => editableSize.name === size.name)!
        .id || size.id;
    const { data } = await mutateCluster({
      analyticsSizeID: newSizeId,
      loaderSizeID: newSizeId,
    });
    notification.success({
      message: 'Success',
      description: data.message,
    });
  }

  const status = getClusterStatus(instance, services[0]);
  const canSave = status === 'running' && currentSize.name !== size.name;
  const { isPlatformActionSupported: isResizeSupported } =
    usePlatformActionsSupport({
      currentPlatform: instance.platform,
      userPlatformActions: user?.platformActions,
      platformKey: PLATFORM_ACTIONS_KEYS.clusterResize,
    });

  return (
    <div className="ClusterEdit ClusterSizeEdit">
      <div className="ClusterEdit__icon">
        {React.createElement(getClusterTypeIcons(currentSize.name, 'fill'))}
      </div>
      <div className="ClusterEdit__content">
        <h3 className="ClusterEdit__title">Cluster Size</h3>
        <p className="ClusterEdit__subtitle">{`${getClusterSize({
          analyticsSizeName: instance.analyticsSize.name,
          analyticsSizeDisplayName: instance.analyticsSize.displayName,
          analyticsSizeIpu: instance.analyticsSize.ipu,
          loaderSizeName: instance.loaderSize.name,
          loaderSizeDisplayName: instance.loaderSize.displayName,
          loaderSizeIpu: instance.loaderSize.ipu,
        })}`}</p>
        {canUpdate && (
          <div className="ClusterSizeEdit__body">
            <div className="ClusterSizeEdit__body-side-wrapper">
              {allAvailableSizes ? (
                <ClusterTypeFieldSlider
                  sizes={allAvailableSizes}
                  editableSizes={editableSizes!}
                  currentSize={size.name}
                  onChange={(newSize: SizeResultItem) => {
                    if (newSize.id !== size.id) {
                      setSize(newSize!);
                    }
                  }}
                  hideMainIcon
                  isEditCluster
                />
              ) : (
                <Spin>
                  <div style={{ height: 82 }} />
                </Spin>
              )}
            </div>
            <div className="ClusterSizeEdit__submit">
              <Tooltip
                title={
                  status !== 'running' ? (
                    'Your cluster must be connected to apply this change'
                  ) : !isResizeSupported ? (
                    <FormattedMessage
                      id="clusterConfiguration.general.actionNotSupportedInCurrentPlan.message"
                      values={{
                        action: intl.formatMessage({
                          id: 'clusterConfiguration.general.actionNotSupportedInCurrentPlan.clusterResize',
                        }),
                      }}
                    />
                  ) : (
                    ''
                  )
                }
              >
                <Button
                  loading={isLoading}
                  disabled={!canSave || !isResizeSupported}
                  type="primary"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ClusterSizeEdit;
