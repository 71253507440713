export enum DataAgentConnectionAction {
  START = 'start',
  STOP = 'stop',
  RESTART = 'restart',
}

export type DataAgentConnectionsResult = {
  body: {
    total: number;
    dataAgents: DataAgentItem[];
  };
  statusCode: number;
  headers: {
    'access-control-allow-origin': string;
    'access-control-allow-methods': string;
    'access-control-allow-headers': string;
    vary: string;
    'content-type': string;
    'content-length': string;
    date: string;
    server: string;
    'x-envoy-upstream-service-time': string;
    connection: string;
    'x-envoy-decorator-operation': string;
  };
};

export type DataAgentConnectionsFilterPayload = {
  column: string; // currently only "NAME" is supported
  search: string;
};

export enum DataAgentStatus {
  NO_AUTHENTICATION_FILE_GENERATED = 0,
  CONNECTED = 1,
  DISCONNECTED = 2,
}

export enum DataAgentComputedStatus {
  UNKNOWN = 'UNKNOWN', // → Unknown
  INCOMPLETE_DATA_AGENT_INFO = 'INCOMPLETE_DATA_AGENT_INFO', //
  HAS_NO_AUTH_FILE = 'HAS_NO_AUTH_FILE',
  BOTH_DA_AND_CONTROLLER_UNREACHABLE_WITH_DA_CONNECTED_SERVICES = 'BOTH_DA_AND_CONTROLLER_UNREACHABLE_WITH_DA_CONNECTED_SERVICES',
  BOTH_DA_AND_CONTROLLER_UNREACHABLE = 'BOTH_DA_AND_CONTROLLER_UNREACHABLE',
  ALL_DA_AND_CONTROLLER_CONNECTED = 'ALL_DA_AND_CONTROLLER_CONNECTED',
  DATA_AGENT_STARTED_BUT_NOT_CONNECTED_TO_ALL_SERVICES = 'DATA_AGENT_STARTED_BUT_NOT_CONNECTED_TO_ALL_SERVICES',
  STOPPED = 'STOPPED',
  CONTROLLER_DISCONNECTED = 'CONTROLLER_DISCONNECTED',
}

export enum DataAgentVersionCompatiblity {
  'UP_TO_DATE' = 'UP_TO_DATE',
  'COMPATIBLE' = 'COMPATIBLE',
  'INCOMPATIBLE' = 'INCOMPATIBLE',
}

export type DataAgentDataSource = { id: number; name: string };

export type DataAgentAndControllerVersionResponse = {
  version: string;
  compatibility: DataAgentVersionCompatiblity;
} | null; // nullable if the DAC is disconnected

export type DataAgentItem = {
  id: number;
  name: string;
  description: string;
  owner: DataAgentUser;
  modifier: DataAgentUser;
  dataSources: DataAgentDataSource[];
  status: DataAgentStatus;
  error: string | null;
  hasAuthFile: boolean;
  dataAgentConnectivityStatusResponse: DataAgentConnectivityStatusResponse;
  dataAgentControllerConnectivityStatusResponse: DataAgentControllerConnectivityStatusResponse;
  dataAgentAndControllerVersionResponse: DataAgentAndControllerVersionResponse;
  tenantId: number;
};
type DataAgentUser = {
  date: number;
  id: number;
  name: string;
  username: string | null;
  status: string | null;
};
export type DataAgentConnectivityStatusResponse = {
  disconnected: boolean;
  connectedServicesAddresses: string[] | null;
  disconnectedServicesAddresses: string[] | null;
};
export type DataAgentControllerConnectivityStatusResponse = {
  disconnected: boolean;
  connectedServicesAddresses: string[] | null;
  disconnectedServicesAddresses: string[] | null;
  isDataAgentStarted: boolean;
};

export type CreateDataAgentConnectionResult =
  | {
      body: {
        id: number;
        name: string;
        description: string;
        owner: DataAgentUser;
        modifier: DataAgentUser;
        dataSources?: null[] | null;
        status: number;
        error?: null;
        hasAuthFile: boolean;
        tenantId: number;
        dataAgentConnectivityStatusResponse?: null;
        dataAgentControllerConnectivityStatusResponse?: null;
      };
      statusCode: number;
      headers: {
        'access-control-allow-origin': string;
        'access-control-allow-methods': string;
        'access-control-allow-headers': string;
        vary: string;
        'content-type': string;
        'content-length': string;
        date: string;
        server: string;
        'x-envoy-upstream-service-time': string;
        connection: string;
        'x-envoy-decorator-operation': string;
      };
    }
  | {
      message: string;
      status: number;
    };

export type UpdateDataAgentConnectionResult = {
  body: string;
  statusCode: number;
  headers: {
    'access-control-allow-origin': string;
    'access-control-allow-methods': string;
    'access-control-allow-headers': string;
    'content-type': string;
    date: string;
    server: string;
    'x-envoy-upstream-service-time': string;
    connection: string;
    'x-envoy-decorator-operation': string;
  };
};

export type DeleteDataAgentConnectionResult = {
  body: string;
  statusCode: number;
  headers: {
    'access-control-allow-origin': string;
    'access-control-allow-methods': string;
    'access-control-allow-headers': string;
    'content-type': string;
    'content-length': string;
    date: string;
    server: string;
    'x-envoy-upstream-service-time': string;
    connection: string;
    'x-envoy-decorator-operation': string;
  };
};

export type GenerateDataAgentConnectionAuthenticationFileResult = {
  body: string;
};

export type ExecuteActionOnDataAgentConnectionResult = {
  body: { status: DataAgentExecuteActionResponseDataStatus };
  statusCode: number;
  headers: {
    'access-control-allow-origin': string;
    'access-control-allow-methods': string;
    'access-control-allow-headers': string;
    'content-type': string;
    'content-length': string;
    date: string;
    server: string;
    'x-envoy-upstream-service-time': string;
    connection: string;
    'x-envoy-decorator-operation': string;
  };
};

export enum DataAgentControlResponseStatusEnum {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  DATAAGENTBUSY = 'DATA_AGENT_BUSY',
  ALREADYSTARTED = 'ALREADY_STARTED',
}

export enum DataAgentExecuteActionResponseDataStatus {
  'SUCCESS',
  'FAILED',
  'DATA_AGENT_BUSY',
  'ALREADY_STARTED',
}

export type SelectedDataAgent = {
  dataAgentID: number;
  name: string;
  tenantID: number;
  description: string | undefined;
};
