import React from 'react';

type HighlightedSearchedTextProps = {
  textToHighlight: string;
  searchWord: string;
};
function HighlightedSearchedText({
  textToHighlight,
  searchWord,
}: HighlightedSearchedTextProps) {
  if (!searchWord) {
    return <>{textToHighlight}</>;
  }

  const regex = new RegExp(`(${searchWord})`, 'gi'); // ensures the search is case-insensitive and matches all occurrences
  const parts = textToHighlight.split(regex);

  return (
    <>
      {parts.map((part, index) =>
        regex.test(part) ? <strong key={index}>{part}</strong> : part,
      )}
    </>
  );
}

export default HighlightedSearchedText;
