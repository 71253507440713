import React from 'react';
import './TableRowActionButton.less';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import clsx from 'clsx';

interface TableRowActionButtonProps extends ButtonProps {}
function TableRowActionButton(props: TableRowActionButtonProps) {
  const { className, ...rest } = props;
  return (
    <Button className={clsx(['table-row-action-button', className])} {...rest}>
      {props.children}
    </Button>
  );
}

export default TableRowActionButton;
