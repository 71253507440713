import { CrownOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import { TagProps } from 'antd/lib/tag';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import './PremiumClusterTag.less';

interface PremiumClusterTag extends TagProps {
  isClusterConfigurationTag?: boolean;
  toolTipWithoutCopilot?: boolean;
}
function PremiumClusterTag({
  isClusterConfigurationTag = false,
  toolTipWithoutCopilot = false,
  ...props
}: PremiumClusterTag) {
  return (
    <Tooltip
      title={
        <FormattedMessage
          id={
            toolTipWithoutCopilot
              ? 'clusterConfiguration.mainTab.premium.tagTooltipWithoutCopilot'
              : 'clusterConfiguration.mainTab.premium.tagTooltip'
          }
        />
      }
    >
      <Tag className="premium-cluster-tag" icon={<CrownOutlined />} {...props}>
        <FormattedMessage id="clusterConfiguration.mainTab.premium.tag" />
      </Tag>
    </Tooltip>
  );
}

export default PremiumClusterTag;
