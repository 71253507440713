import './ClusterSizeCard.less';

import React from 'react';
import Icon from '@ant-design/icons';
import { useHistory } from 'react-router';
import MainCard from '../MainCard/MainCard';
import { Instance } from 'types/cluster';
import { getClusterTypeIcons, getClusterSize } from 'utils/cluster';
import { ReactComponent as AnalyticsIcon } from 'images/analytics.svg';

interface ClusterSizeCardProps {
  instance: Instance;
}

function ClusterSizeCard({ instance }: ClusterSizeCardProps) {
  let history = useHistory();

  let canUpdate = instance.clusterPolicy.clusterPolicy.includes('update');

  return (
    <div className="ClusterSizeCard">
      <MainCard
        icon={React.createElement(getClusterTypeIcons(instance.csize, 'fill'))}
        title={'Cluster Size'}
        subtitle={getClusterSize({
          analyticsSizeName: instance.analyticsSize.name,
          analyticsSizeDisplayName: instance.analyticsSize.displayName,
          analyticsSizeIpu: instance.analyticsSize.ipu,
          loaderSizeName: instance.loaderSize.name,
          loaderSizeDisplayName: instance.loaderSize.displayName,
          loaderSizeIpu: instance.loaderSize.ipu,
        })}
        hideFooter={!canUpdate}
        footerText={'Configure Size & Services'}
        onFooterClick={() => {
          history.push(
            `/clusters/${instance.name}?tab=configurations#cluster-size`,
          );
        }}
      >
        <div className="MainCard__body-section ClusterSizeCard__section">
          <div className="MainCard__icon" style={{ color: '#99A6B0' }}>
            <Icon component={AnalyticsIcon} />
          </div>
          <div className="MainCard__content">
            <h3 className="MainCard__title">Analytics Node</h3>
            <p className="MainCard__subtitle">
              {`${instance.analyticsNodes} Analytics Service - ${instance.analyticsSize.ipu} IPU`}
            </p>
            <div className="BIToolsCard">
              <p className="BIToolsCard__hint">
                Increasing Analytics improves the query throughput for high
                concurrency workloads.
              </p>
            </div>
          </div>
        </div>

        <div className="MainCard__body-section ClusterSizeCard__section">
          <div className="MainCard__icon" style={{ color: '#99A6B0' }}>
            <Icon component={AnalyticsIcon} />
          </div>
          <div className="MainCard__content">
            <h3 className="MainCard__title">Loader Node</h3>
            <p className="MainCard__subtitle">
              {`${instance.loaderNodes} Loader Service - ${instance.loaderSize.ipu} IPU`}
            </p>
            <div className="BIToolsCard">
              <p className="BIToolsCard__hint">
                Increasing Loaders improve running and loading multiple parallel
                workloads for the main <strong>Cluster</strong> and its{' '}
                <strong>Sub Clusters</strong>.
              </p>
            </div>
          </div>
        </div>
      </MainCard>
    </div>
  );
}

export default ClusterSizeCard;
