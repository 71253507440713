import React from 'react';
import { Modal } from 'antd';
import { useIntl } from 'react-intl';
import { DataAgentDataSource, DataAgentItem } from 'types/dataAgent.types';

type SingleDataAgentProps = {
  isMultipleDataAgents: false;
  isDataAgentConnectionCantBeDeleted: boolean;
  dataAgentName: string;
  dataSources?: DataAgentDataSource[];
  onOkConfirmDeleteSingle: () => void;
};
type MultipleDataAgentProps = {
  isMultipleDataAgents: true;
  areAllSelectedDataAgentConnectionsCantBeDeleted: boolean;
  onOkCantBeDeleted: () => void;
  deletableDataAgentConnections: DataAgentItem[];
  dataAgentConnectionsCantBeDeleted: DataAgentItem[];
  onOkConfirmDeleteMultiple: () => void;
};
type DataAgentDeleteModalProps = SingleDataAgentProps | MultipleDataAgentProps;

function useDataAgentDeleteModal() {
  const intl = useIntl();

  function showDeleteModal(props: DataAgentDeleteModalProps) {
    if (!props.isMultipleDataAgents) {
      if (props.isDataAgentConnectionCantBeDeleted) {
        Modal.warning({
          title: intl.formatMessage(
            {
              id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.deleteConfirm.titleSingleCantBeDeleted',
            },
            {
              dataAgentName: <strong>{props.dataAgentName}</strong>,
            },
          ),
          content: intl.formatMessage(
            {
              id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.deleteConfirm.contentSingleCantBeDeleted',
            },

            {
              dataSources: props.dataSources
                ?.map(dataSource => dataSource.name)
                .join(', '),
            },
          ),
          okText: intl.formatMessage({
            id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.deleteConfirm.submitButtonCancel',
          }),
          okType: 'default',
          centered: true,
        });
      } else {
        const deleteConfirmModalMessages = {
          title: intl.formatMessage(
            {
              id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.deleteConfirm.titleSingle',
            },
            {
              dataAgentName: <strong>{props.dataAgentName}</strong>,
            },
          ),
          content: intl.formatMessage(
            {
              id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.deleteConfirm.contentSingle',
            },
            { dataAgentName: <strong>{props.dataAgentName}</strong> },
          ),
          submitButton: intl.formatMessage({
            id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.deleteConfirm.submitButton',
          }),
        };

        Modal.confirm({
          title: deleteConfirmModalMessages.title,
          content: deleteConfirmModalMessages.content,
          okText: deleteConfirmModalMessages.submitButton,
          centered: true,
          async onOk() {
            props.onOkConfirmDeleteSingle();
          },
        });
      }
    } else {
      if (props.areAllSelectedDataAgentConnectionsCantBeDeleted) {
        const deleteConfirmModalMessages = {
          title: intl.formatMessage({
            id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.deleteConfirm.titleMultipleCantBeDeleted',
          }),
          content: intl.formatMessage({
            id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.deleteConfirm.contentMultipleCantBeDeleted',
          }),
          attachedTo: intl.formatMessage({
            id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.deleteConfirm.attachedTo',
          }),
          submitButton: intl.formatMessage({
            id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.deleteConfirm.multipleSubmit',
          }),
        };
        Modal.warning({
          title: deleteConfirmModalMessages.title,
          content: (
            <section className="data-agent-delete-modal-content">
              <label>{deleteConfirmModalMessages.content}</label>
              {props.dataAgentConnectionsCantBeDeleted?.length ? (
                <>
                  <ul>
                    {props.dataAgentConnectionsCantBeDeleted.map(dataAgent => (
                      <li key={dataAgent.id}>
                        <b>{dataAgent.name}</b>{' '}
                        {deleteConfirmModalMessages.attachedTo}{' '}
                        <>
                          {dataAgent.dataSources
                            ?.map(dataSource => dataSource.name)
                            .join(', ')}
                        </>
                      </li>
                    ))}
                  </ul>
                </>
              ) : null}
            </section>
          ),
          okText: deleteConfirmModalMessages.submitButton,
          okType: 'default',
          centered: true,
          onOk() {
            props.onOkCantBeDeleted();
          },
        });
      } else {
        const deleteConfirmModalMessages = {
          title: intl.formatMessage({
            id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.deleteConfirm.titleMultiple',
          }),
          labelHeadsUp: intl.formatMessage({
            id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.deleteConfirm.contentMultiple.labelHeadsUp',
          }),
          attachedTo: intl.formatMessage({
            id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.deleteConfirm.attachedTo',
          }),
          labelCantDelete: intl.formatMessage(
            {
              id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.deleteConfirm.contentMultiple.labelCantDelete',
            },
            {
              note: <strong>Note: </strong>,
            },
          ),
        };
        Modal.confirm({
          title: deleteConfirmModalMessages.title,
          content: (
            <section className="data-agent-delete-modal-content">
              <label>{deleteConfirmModalMessages.labelHeadsUp}</label>
              <ul>
                {props.deletableDataAgentConnections.map(dataAgent => (
                  <li key={dataAgent.id}>
                    <b>{dataAgent.name}</b>
                  </li>
                ))}
              </ul>
              {props.dataAgentConnectionsCantBeDeleted?.length ? (
                <>
                  <label>{deleteConfirmModalMessages.labelCantDelete}</label>
                  <ul>
                    {props.dataAgentConnectionsCantBeDeleted.map(dataAgent => (
                      <li key={dataAgent.id}>
                        <b>{dataAgent.name}</b>{' '}
                        {deleteConfirmModalMessages.attachedTo}{' '}
                        <>
                          {dataAgent.dataSources
                            ?.map(dataSource => dataSource.name)
                            .join(', ')}
                        </>
                      </li>
                    ))}
                  </ul>
                </>
              ) : null}
            </section>
          ),
          okText: 'Delete',
          centered: true,
          async onOk() {
            props.onOkConfirmDeleteMultiple();
          },
        });
      }
    }
  }

  return { showDeleteModal };
}

export default useDataAgentDeleteModal;
