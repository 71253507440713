import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { useIntl } from 'react-intl';
import { QueryServerKeys } from 'constants/QueryServerKeys';
import { getErrorMessage } from 'utils/errors';
import { queryClient } from 'index';
import {
  createDataAgentConnection,
  dataAgentConnections,
  deleteDataAgentConnection,
  executeActionOnDataAgentConnection,
  generateDataAgentConnectionAuthenticationFile,
  updateDataAgentConnection,
} from 'services/dataAgent';
import {
  DataAgentConnectionAction,
  DataAgentConnectionsFilterPayload,
  DataAgentExecuteActionResponseDataStatus,
} from 'types/dataAgent.types';
import useDataAgentHelper from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterDataAgentEdit/useDataAgentHelper';
import {
  displaySuccessAntdMessage,
  displayErrorAntdMessage,
  wait,
} from 'utils';

export function useDataAgentConnections({
  instanceID,
  page = 0,
  limit = 10,
  filters,
}: {
  instanceID: string;
  page: number;
  limit: number;
  filters?: DataAgentConnectionsFilterPayload[];
}) {
  return useQuery(
    [
      QueryServerKeys.DATA_AGENT_CONNECTION.CONNECTIONS_LIST,
      instanceID,
      page,
      limit,
      filters,
    ],
    async () => {
      const result = await dataAgentConnections({
        instanceID,
        page,
        limit,
        filters,
      });
      return result?.data?.body;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
}

export function useCreateDataAgentConnection({
  instanceID,
}: {
  instanceID: string;
}) {
  const intl = useIntl();
  return useMutation(
    async ({
      tenantID,
      name,
      description,
    }: {
      tenantID: number;
      name: string;
      description?: string;
    }) => {
      const result = await createDataAgentConnection({
        instanceID,
        tenantID,
        name,
        ...(description
          ? {
              description,
            }
          : {}),
      });

      if ('status' in result.data && 'message' in result.data) {
        if (!result?.data?.status?.toString()?.startsWith('2')) {
          throw Error(result?.data?.message);
        }
      }
      return result;
    },
    {
      mutationKey: [
        QueryServerKeys.DATA_AGENT_CONNECTION.CREATE_CONNECTION,
        instanceID,
      ],
      onSuccess(result) {
        queryClient.invalidateQueries(QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY);
        queryClient.invalidateQueries(
          QueryServerKeys.DATA_AGENT_CONNECTION.CONNECTIONS_LIST,
        );

        displaySuccessAntdMessage(
          result.status.toString().startsWith('2')
            ? intl.formatMessage({
                id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.message.createSuccessful',
              })
            : result.status.toString(),
        );
      },
      onError(err: AxiosError) {
        let responseData = err.response?.data;
        if (typeof err.response?.data?.body === 'string')
          responseData.message = responseData.body;
        const error = {
          response: { data: err.response?.data },
        };
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorAntdMessage(errorMessage);
        }
      },
    },
  );
}

export function useUpdateDataAgentConnection({
  instanceID,
}: {
  instanceID: string;
}) {
  const intl = useIntl();
  return useMutation(
    async ({
      dataAgentID,
      description,
    }: {
      dataAgentID: number;
      description: string;
    }) => {
      return await updateDataAgentConnection({
        instanceID,
        dataAgentID,
        description,
      });
    },
    {
      mutationKey: [
        QueryServerKeys.DATA_AGENT_CONNECTION.UPDATE_CONNECTION,
        instanceID,
      ],
      onSuccess(result) {
        queryClient.invalidateQueries(QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY);
        queryClient.invalidateQueries(
          QueryServerKeys.DATA_AGENT_CONNECTION.CONNECTIONS_LIST,
        );

        displaySuccessAntdMessage(
          result.status.toString().startsWith('2')
            ? intl.formatMessage({
                id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.message.updateSuccessful',
              })
            : result.data.statusCode.toString(),
        );
      },
      onError(error: AxiosError) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorAntdMessage(errorMessage);
        }
      },
    },
  );
}

export function useDeleteDataAgentConnection({
  instanceID,
}: {
  instanceID: string;
}) {
  const intl = useIntl();
  return useMutation(
    async ({
      dataAgentConnectionIDs,
    }: {
      dataAgentConnectionIDs: number[];
      dataAgentNames: string[];
    }) => {
      return await deleteDataAgentConnection({
        instanceID,
        dataAgentConnectionIDs,
      });
    },
    {
      mutationKey: [
        QueryServerKeys.DATA_AGENT_CONNECTION.DELETE_CONNECTION,
        instanceID,
      ],
      onSuccess(result, { dataAgentNames }) {
        queryClient.invalidateQueries(QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY);
        queryClient.invalidateQueries(
          QueryServerKeys.DATA_AGENT_CONNECTION.CONNECTIONS_LIST,
        );

        const count = dataAgentNames.length;
        displaySuccessAntdMessage(
          result.status.toString().startsWith('2')
            ? intl.formatMessage(
                {
                  id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.message.deleteSuccessful',
                },
                {
                  count,
                  name: count === 1 ? dataAgentNames[0] : '',
                  names: dataAgentNames.join(', '),
                },
              )
            : '',
        );
      },
      onError(error: AxiosError) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorAntdMessage(errorMessage);
        }
      },
    },
  );
}

export function useGenerateDataAgentConnectionAuthneticationFile({
  instanceID,
  tenantName,
  dataAgentID,
  dataAgentName,
}: {
  instanceID: string;
  dataAgentName?: string;
  tenantName: string;
  dataAgentID: number;
}) {
  const { downloadDataAgentAuthFile } = useDataAgentHelper();
  const intl = useIntl();
  return useMutation(
    async () => {
      const result = await generateDataAgentConnectionAuthenticationFile({
        instanceID,
        dataAgentID,
        tenantName,
      });
      return result;
    },
    {
      mutationKey: [
        QueryServerKeys.DATA_AGENT_CONNECTION.GENERATE_AUTHENTICATION_FILE,
        instanceID,
        dataAgentID,
      ],
      onSuccess({ data, status }) {
        try {
          downloadDataAgentAuthFile({ downloadUrl: data.body });

          queryClient.invalidateQueries(
            QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY,
          );
          queryClient.invalidateQueries(
            QueryServerKeys.DATA_AGENT_CONNECTION.CONNECTIONS_LIST,
          );

          displaySuccessAntdMessage(
            status.toString().startsWith('2')
              ? intl.formatMessage({
                  id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.message.authFileGeneratedSuccessful',
                })
              : status.toString(),
          );
        } catch (error) {
          displayErrorAntdMessage((error as AxiosError).message);
        }
      },
      onError(error: AxiosError) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorAntdMessage(errorMessage);
        }
      },
    },
  );
}

export function useExecuteActionOnDataAgentConnection({
  instanceID,
  dataAgentID,
  dataAgentName,
  tenantName,
}: {
  instanceID: string;
  dataAgentID: number;
  dataAgentName: string;
  tenantName: string;
}) {
  const intl = useIntl();
  return useMutation(
    async ({ action }: { action: DataAgentConnectionAction }) => {
      return await executeActionOnDataAgentConnection({
        instanceID,
        dataAgentID,
        tenantName,
        action,
      });
    },
    {
      mutationKey: [
        QueryServerKeys.DATA_AGENT_CONNECTION.EXECUTE_ACTION,
        instanceID,
        dataAgentID,
      ],
      async onSuccess(result, { action }) {
        await wait(2000); // added as the 200 response indicates that the request has been sent successfully to the data agent through the controller, but not making sure the action is applied and finished
        queryClient.invalidateQueries(QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY);
        queryClient.invalidateQueries(
          QueryServerKeys.DATA_AGENT_CONNECTION.CONNECTIONS_LIST,
        );

        const dataStatus = result.data.body.status;

        const unsuccessfulMessageIDMap = new Map([
          [
            DataAgentExecuteActionResponseDataStatus.FAILED,
            'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.message.actionFailed',
          ],
          [
            DataAgentExecuteActionResponseDataStatus.DATA_AGENT_BUSY,
            'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.message.actionBusy',
          ],
          [
            DataAgentExecuteActionResponseDataStatus.ALREADY_STARTED,
            'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.message.alreadyStarted',
          ],
        ]);

        if (unsuccessfulMessageIDMap.has(dataStatus)) {
          displayErrorAntdMessage(
            intl.formatMessage({
              id: unsuccessfulMessageIDMap.get(dataStatus),
            }),
          );
        } else {
          const actionMessageID =
            action === DataAgentConnectionAction.START
              ? 'startActionSuccessful'
              : action === DataAgentConnectionAction.STOP
              ? 'stopActionSuccessful'
              : action === DataAgentConnectionAction.RESTART
              ? 'restartActionSuccessful'
              : 'actionSuccessful';
          displaySuccessAntdMessage(
            result.status.toString().startsWith('2')
              ? intl.formatMessage(
                  {
                    id: `clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.message.${actionMessageID}`,
                  },
                  {
                    dataAgentName,
                  },
                )
              : '',
          );
        }
      },
      onError(error: AxiosError) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorAntdMessage(errorMessage);
        }
      },
    },
  );
}
