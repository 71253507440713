import React, { PropsWithChildren } from 'react';
import { Empty } from 'antd';

type EmptyTableProps = {
  description: string | JSX.Element;
};
function EmptyTable({
  description,
  children,
}: PropsWithChildren<EmptyTableProps>) {
  return (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={description}>
      {children}
    </Empty>
  );
}

export default EmptyTable;
