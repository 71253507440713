import './ClusterTypeField.less';

import React, { useMemo } from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import { Form, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { SizesResult } from 'types/cluster';
import { InstancePlatform } from 'utils/platforms';
import { clusterFormFieldNames } from 'components/ClusterCreate/ClusterForm/ClusterForm';
import ClusterTypeFieldSlider from 'components/ClusterCreate/ClusterTypeField/ClusterTypeFieldSlider/ClusterTypeFieldSlider';

interface ClusterTypeFieldProps {
  clusterSizesData: SizesResult;
  currentPlatform: InstancePlatform;
  azureClusterSize?: string;
}

function ClusterTypeField({
  clusterSizesData,
  currentPlatform,
  azureClusterSize = 'medium',
}: ClusterTypeFieldProps) {
  const sizes = useMemo(
    () => clusterSizesData?.sizes ?? [],
    [clusterSizesData],
  );
  const editableSizes = useMemo(
    () => clusterSizesData?.allSizes ?? [],
    [clusterSizesData],
  );

  if (sizes.length === 0) {
    return (
      <div className="size-fields__skeleton-wrapper">
        <Spin>
          <div className="size-fields__skeleton" />
        </Spin>
      </div>
    );
  }
  return (
    <Form.Item
      name={clusterFormFieldNames.csize}
      rules={[
        {
          required: true,
          message: <FormattedMessage id="clusterForm.clusterSizeRequired" />,
        },
      ]}
      className="size-fields cluster-input-with-hint"
      label={<FormattedMessage id="clusterForm.clusterSizeLabel" />}
      extra={
        <>
          <InfoCircleFilled />{' '}
          <FormattedMessage
            id="clusterForm.clusterSizeExtra"
            values={{ supportLink: <Link to="/support">Contact Support</Link> }}
          />
        </>
      }
    >
      <ClusterTypeFieldSlider
        sizes={sizes}
        editableSizes={editableSizes}
        currentPlatform={currentPlatform}
      />
    </Form.Item>
  );
}

export default ClusterTypeField;
