import React from 'react';
import { Form, Select } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { searchFilterOption } from 'utils';
import { clusterFormFieldNames } from 'components/ClusterCreate/ClusterForm/ClusterForm';

interface InstallationFieldProps {
  clusterInstallation?: string;
}

function InstallationField({ clusterInstallation }: InstallationFieldProps) {
  const intl = useIntl();

  const installations = ['typical', 'custom'];
  const installation =
    installations.length === 0 ? null : clusterInstallation ?? installations[0];

  return (
    <Form.Item
      name={clusterFormFieldNames.installation}
      initialValue={installation}
      rules={[
        {
          required: true,
          message: (
            <FormattedMessage id="clusterForm.clusterInstallationRequired" />
          ),
        },
      ]}
      label={<FormattedMessage id="clusterForm.clusterInstallationLabel" />}
    >
      <Select
        showSearch
        filterOption={searchFilterOption}
        loading={installations.length === 0}
        placeholder={intl.formatMessage({
          id: 'clusterForm.clusterInstallationPlaceholder',
        })}
      >
        {installations?.map(installation => (
          <Select.Option key={installation} value={installation}>
            {installation[0].toUpperCase() + installation.slice(1)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default InstallationField;
