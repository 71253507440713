import React from 'react';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { PresetColorType, PresetStatusColorType } from 'antd/lib/_util/colors';
import {
  DataAgentComputedStatus,
  DataAgentConnectivityStatusResponse,
  DataAgentControllerConnectivityStatusResponse,
  DataAgentVersionCompatiblity,
} from 'types/dataAgent.types';

type antdTagColor = PresetColorType | PresetStatusColorType;

type TagOptionsComputedState = {
  icon: JSX.Element;
  color: antdTagColor;
  label: JSX.Element;
};

const dataAgentStatusTagDefaults: TagOptionsComputedState = {
  icon: <ExclamationCircleOutlined />,
  color: 'default',
  label: (
    <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.tag.unknown" />
  ),
};
const dataAgentStatusTagNoAuthFileGenerated: TagOptionsComputedState = {
  icon: <ExclamationCircleOutlined />,
  color: 'default',
  label: (
    <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.tag.noAuthFileGenerated" />
  ),
};
const dataAgentStatusTagDisconnected: TagOptionsComputedState = {
  icon: <CloseCircleOutlined />,
  color: 'error',
  label: (
    <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.tag.disconnected" />
  ),
};
const dataAgentStatusTagStarted: TagOptionsComputedState = {
  icon: <CheckCircleOutlined />,
  color: 'success',
  label: (
    <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.tag.started" />
  ),
};
const dataAgentStatusTagStartedWithWarning: TagOptionsComputedState = {
  icon: <CheckCircleOutlined color="warning" />,
  color: 'warning',
  label: (
    <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.tag.started" />
  ),
};
const dataAgentStatusTagStopped: TagOptionsComputedState = {
  icon: <CloseCircleOutlined />,
  color: 'error',
  label: (
    <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.tag.stopped" />
  ),
};

function useDataAgentHelper() {
  const tagOptionsUnknown: TagOptionsComputedState = dataAgentStatusTagDefaults;
  const tagOptionsIncompleteDataAgentInfo: TagOptionsComputedState =
    dataAgentStatusTagDefaults;
  const tagOptionsNoAuthFileGenerated: TagOptionsComputedState =
    dataAgentStatusTagNoAuthFileGenerated;
  const tagOptionsBothDAAndControllerDisconnectedWithDAConnectedServices: TagOptionsComputedState =
    dataAgentStatusTagStartedWithWarning;
  const tagOptionsBothDAAndControllerDisconnected: TagOptionsComputedState =
    dataAgentStatusTagDisconnected;
  const tagOptionsConnected: TagOptionsComputedState =
    dataAgentStatusTagStarted;
  const tagOptionsDataAgentStartedButNotConnectedToAllServices: TagOptionsComputedState =
    dataAgentStatusTagStartedWithWarning;
  const tagOptionsStopped: TagOptionsComputedState = dataAgentStatusTagStopped;
  const tagOptionsControlledDisconnected: TagOptionsComputedState =
    dataAgentStatusTagStartedWithWarning;

  const dataAgentStatusTagMap = new Map<
    DataAgentComputedStatus,
    TagOptionsComputedState
  >([
    [DataAgentComputedStatus.UNKNOWN, { ...tagOptionsUnknown }],
    [
      DataAgentComputedStatus.INCOMPLETE_DATA_AGENT_INFO,
      { ...tagOptionsIncompleteDataAgentInfo },
    ],
    [
      DataAgentComputedStatus.HAS_NO_AUTH_FILE,
      { ...tagOptionsNoAuthFileGenerated },
    ],
    [
      DataAgentComputedStatus.BOTH_DA_AND_CONTROLLER_UNREACHABLE_WITH_DA_CONNECTED_SERVICES,
      { ...tagOptionsBothDAAndControllerDisconnectedWithDAConnectedServices },
    ],
    [
      DataAgentComputedStatus.BOTH_DA_AND_CONTROLLER_UNREACHABLE,
      { ...tagOptionsBothDAAndControllerDisconnected },
    ],
    [
      DataAgentComputedStatus.ALL_DA_AND_CONTROLLER_CONNECTED,
      { ...tagOptionsConnected },
    ],
    [
      DataAgentComputedStatus.DATA_AGENT_STARTED_BUT_NOT_CONNECTED_TO_ALL_SERVICES,
      { ...tagOptionsDataAgentStartedButNotConnectedToAllServices },
    ],
    [DataAgentComputedStatus.STOPPED, { ...tagOptionsStopped }],
    [
      DataAgentComputedStatus.CONTROLLER_DISCONNECTED,
      { ...tagOptionsControlledDisconnected },
    ],
  ]);

  function getDataAgentComputedState({
    hasAuthFile,
    dataAgentResponse,
    controllerResponse,
  }: {
    hasAuthFile: boolean;
    dataAgentResponse: DataAgentConnectivityStatusResponse;
    controllerResponse: DataAgentControllerConnectivityStatusResponse;
  }) {
    let dataAgentComputedStatus: DataAgentComputedStatus =
      DataAgentComputedStatus.UNKNOWN;

    if (!dataAgentResponse || !controllerResponse) {
      dataAgentComputedStatus =
        DataAgentComputedStatus.INCOMPLETE_DATA_AGENT_INFO; // represented as ExclamationCircleOutlined unknown
    } else if (!hasAuthFile) {
      dataAgentComputedStatus = DataAgentComputedStatus.HAS_NO_AUTH_FILE; // represented as ExclamationCircleOutlined N/A
    } else if (
      hasAuthFile &&
      dataAgentResponse.disconnected &&
      controllerResponse.disconnected &&
      dataAgentResponse.connectedServicesAddresses?.length &&
      dataAgentResponse.disconnectedServicesAddresses?.length
    ) {
      dataAgentComputedStatus =
        DataAgentComputedStatus.BOTH_DA_AND_CONTROLLER_UNREACHABLE_WITH_DA_CONNECTED_SERVICES; // represented as CheckCircleOutlined yellow Started
    } else if (
      hasAuthFile &&
      dataAgentResponse.disconnected &&
      controllerResponse.disconnected
    ) {
      dataAgentComputedStatus =
        DataAgentComputedStatus.BOTH_DA_AND_CONTROLLER_UNREACHABLE; // represented as CloseCircleOutlined Disconnected
    } else if (
      !dataAgentResponse.disconnected &&
      !controllerResponse.disconnected &&
      controllerResponse.isDataAgentStarted &&
      !dataAgentResponse.disconnectedServicesAddresses?.length &&
      !controllerResponse.disconnectedServicesAddresses?.length
    ) {
      dataAgentComputedStatus =
        DataAgentComputedStatus.ALL_DA_AND_CONTROLLER_CONNECTED; // represented as CheckCircleOutlined Started
    } else if (
      !controllerResponse.disconnected &&
      controllerResponse.isDataAgentStarted &&
      dataAgentResponse.disconnected
    ) {
      dataAgentComputedStatus =
        DataAgentComputedStatus.DATA_AGENT_STARTED_BUT_NOT_CONNECTED_TO_ALL_SERVICES; // represented as CheckCircleOutlined yellow Started
    } else if (
      !controllerResponse.disconnected &&
      !controllerResponse.isDataAgentStarted
    ) {
      dataAgentComputedStatus = DataAgentComputedStatus.STOPPED; // represented as CloseCircleOutlined stopped
    } else if (
      !dataAgentResponse.disconnected &&
      controllerResponse.disconnected
    ) {
      dataAgentComputedStatus = DataAgentComputedStatus.CONTROLLER_DISCONNECTED; // represented as CheckCircleOutlined yellow Started
    }
    return dataAgentComputedStatus;
  }

  function getDataAgentTooltipTitle({
    dataAgentComputedStatus,
    dataAgentDisconnectedServices,
    controllerDisconnectedServices,
    dataAgentError,
  }: {
    dataAgentComputedStatus: DataAgentComputedStatus;
    dataAgentDisconnectedServices: string[] | null;
    controllerDisconnectedServices: string[] | null;
    dataAgentError: string | null;
  }) {
    const disconnectedServices = (
      <>
        {dataAgentDisconnectedServices?.length ? (
          <section className="data-agent-connections-table__overlay-status-tooltip">
            <b>
              <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.tag.tooltip.dataAgentDisconnectedServices" />
            </b>
            <ul>
              {dataAgentDisconnectedServices?.map(serviceName => (
                <li key={serviceName}>
                  <span>{serviceName}</span>
                </li>
              ))}
            </ul>
          </section>
        ) : null}
        {controllerDisconnectedServices?.length ? (
          <section className="data-agent-connections-table__overlay-status-tooltip">
            <b>
              <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.tag.tooltip.dataAgentControllerDisconnectedServices" />
            </b>
            <ul>
              {controllerDisconnectedServices?.map(serviceName => (
                <li key={serviceName}>
                  <span>{serviceName}</span>
                </li>
              ))}
            </ul>
          </section>
        ) : null}
      </>
    );

    return [
      DataAgentComputedStatus.BOTH_DA_AND_CONTROLLER_UNREACHABLE_WITH_DA_CONNECTED_SERVICES,
      DataAgentComputedStatus.DATA_AGENT_STARTED_BUT_NOT_CONNECTED_TO_ALL_SERVICES,
    ].includes(dataAgentComputedStatus) &&
      dataAgentDisconnectedServices?.length ? (
      disconnectedServices
    ) : dataAgentComputedStatus ===
      DataAgentComputedStatus.CONTROLLER_DISCONNECTED ? (
      <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.tag.tooltip.dataAgentControllerNotReachabled" />
    ) : dataAgentError ? (
      dataAgentError
    ) : null;
  }

  function downloadDataAgentAuthFile({ downloadUrl }: { downloadUrl: string }) {
    try {
      // Create a Link Element and Trigger the Download
      const link = document.createElement('a');
      link.href = downloadUrl;

      // Append the link to the document body and click it to start the download
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link element and revoking the object URL
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.log('error in download file');
      throw error;
    }
  }

  const dataAgentVersionMap = new Map<
    DataAgentVersionCompatiblity,
    {
      tooltip: string | JSX.Element;
      icon: JSX.Element;
    }
  >([
    [
      DataAgentVersionCompatiblity.COMPATIBLE,
      {
        tooltip: (
          <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.versionStatus.compatible" />
        ),
        icon: <ExclamationCircleOutlined />,
      },
    ],
    [
      DataAgentVersionCompatiblity.INCOMPATIBLE,
      {
        tooltip: (
          <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.versionStatus.incompatible" />
        ),
        icon: <CloseCircleOutlined />,
      },
    ],
  ]);

  return {
    dataAgentStatusTagMap,
    dataAgentStatusTagDefaults,
    getDataAgentComputedState,
    getDataAgentTooltipTitle,
    downloadDataAgentAuthFile,
    dataAgentVersionMap,
  };
}

export default useDataAgentHelper;
