import { Tag, Tooltip } from 'antd';
import React from 'react';
import {
  DataAgentConnectivityStatusResponse,
  DataAgentControllerConnectivityStatusResponse,
  DataAgentStatus,
} from 'types/dataAgent.types';
import useDataAgentHelper from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterDataAgentEdit/useDataAgentHelper';

type Props = {
  id: number;
  status: DataAgentStatus;
  hasAuthFile: boolean;
  dataAgentResponse: DataAgentConnectivityStatusResponse;
  controllerResponse: DataAgentControllerConnectivityStatusResponse;
  dataAgentError: string | null;
};
function DataAgentStateCellRenderer({
  id,
  status,
  hasAuthFile,
  dataAgentResponse,
  controllerResponse,
  dataAgentError,
}: Props) {
  const {
    dataAgentStatusTagDefaults,
    dataAgentStatusTagMap,
    getDataAgentComputedState,
    getDataAgentTooltipTitle,
  } = useDataAgentHelper();

  const dataAgentComputedStatus = getDataAgentComputedState({
    dataAgentResponse,
    controllerResponse,
    hasAuthFile,
  });

  const statusTagTooltip = getDataAgentTooltipTitle({
    dataAgentComputedStatus,
    dataAgentDisconnectedServices:
      dataAgentResponse.disconnectedServicesAddresses,
    controllerDisconnectedServices:
      controllerResponse.disconnectedServicesAddresses,
    dataAgentError,
  });

  const currentStatusTagOptions = dataAgentStatusTagMap.get(
    dataAgentComputedStatus,
  );

  return (
    <Tooltip title={statusTagTooltip}>
      <Tag
        className="status-tag"
        icon={currentStatusTagOptions?.icon ?? dataAgentStatusTagDefaults.icon}
        color={
          currentStatusTagOptions?.color ?? dataAgentStatusTagDefaults.color
        }
        key={id}
      >
        {currentStatusTagOptions?.label ?? status}
      </Tag>
    </Tooltip>
  );
}

export default DataAgentStateCellRenderer;
