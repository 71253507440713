import React, { useContext, useState } from 'react';
import './DataAgentConnections.less';
import {
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import useDataAgentConnectionsColumns from './useDataAgentConnectionsColumns';
import DataAgentConnectionModal from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterDataAgentEdit/DataAgentConnectionModal/DataAgentConnectionModal';
import useDataAgentDeleteModal from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterDataAgentEdit/DataAgentConnections/useDataAgentDeleteModal/useDataAgentDeleteModal';
import {
  useDataAgentConnections,
  useDeleteDataAgentConnection,
} from 'hooks/dataAgent';
import { DataAgentItem, SelectedDataAgent } from 'types/dataAgent.types';
import { SessionContext } from 'auth/SessionProvider';
import {
  getPaginationProps,
  getRowSelectionProps,
} from 'components/reusables/antdReusables/antdFunctions';
import EmptyTable from 'components/reusables/antdReusables/components/EmptyTable';
import ErrorResultTable from 'components/reusables/antdReusables/components/ErrorResultTable';

function DataAgentConnections({
  instanceID,
  clusterName,
}: {
  instanceID: string;
  clusterName: string;
}) {
  const { user } = useContext(SessionContext);

  const [showDataAgentConnectionModal, setShowDataAgentConnectionModal] =
    useState(false);

  function openDataAgentConnectionModal() {
    setShowDataAgentConnectionModal(true);
  }

  function closeDataAgentConnectionModal() {
    setShowDataAgentConnectionModal(false);
    setSelectedDataAgentConnection(null);
  }

  const [selectedDataAgentConnection, setSelectedDataAgentConnection] =
    useState<SelectedDataAgent | null>();

  const [page, setPage] = useState(0);
  const limit = 10;

  const { showDeleteModal } = useDataAgentDeleteModal();

  const {
    mutateAsync: mutateDeleteDataAgentConnection,
    isLoading: isDeleteDataAgentConnectionLoading,
  } = useDeleteDataAgentConnection({ instanceID });
  const [selectedRows, setSelectedRows] = useState<DataAgentItem[]>([]);

  const [searchText, setSearchText] = useState('');
  function updateSearchTextWithValue(value: string) {
    setSearchText(value);
  }
  function resetSearchText() {
    setSearchText('');
  }

  const { columns, dataAgentConnectionsFieldNames } =
    useDataAgentConnectionsColumns({
      userId: user.uuid,
      instanceID,
      clusterName,
      setSelectedDataAgentConnection,
      openDataAgentConnectionModal,
      searchText,
      updateSearchTextWithValue,
      resetSearchText,
    });

  const {
    data: dataAgentConnectionsDataBody,
    isLoading: isDataAgentConnectionsLoading,
    refetch: refetchDataAgentConnections,
    isRefetching: isDataAgentConnectionsRefetching,
    error: dataAgentConnectionsError,
  } = useDataAgentConnections({
    instanceID,
    page,
    limit,
    ...(searchText
      ? {
          filters: [
            {
              column: 'NAME',
              search: searchText,
            },
          ],
        }
      : {}),
  });
  const dataAgentConnections = dataAgentConnectionsDataBody?.dataAgents;
  const dataAgentConnectionsTotals = dataAgentConnectionsDataBody?.total;

  function handleRefetchDataAgentConnections() {
    refetchDataAgentConnections();
  }

  function deselectAllRows() {
    setSelectedRows([]);
  }
  async function deleteSelectedRows(e: React.MouseEvent<HTMLElement>) {
    const deletableDataAgentConnections: DataAgentItem[] = [];
    const dataAgentConnectionsCantBeDeleted: DataAgentItem[] = [];

    selectedRows.forEach(dataAgent => {
      if (dataAgent.dataSources?.length) {
        dataAgentConnectionsCantBeDeleted.push(dataAgent);
      } else {
        deletableDataAgentConnections.push(dataAgent);
      }
    });

    const areAllSelectedDataAgentConnectionsCantBeDeleted =
      selectedRows.length === dataAgentConnectionsCantBeDeleted.length;

    showDeleteModal({
      isMultipleDataAgents: true,
      areAllSelectedDataAgentConnectionsCantBeDeleted,
      onOkCantBeDeleted: () => {},
      deletableDataAgentConnections,
      dataAgentConnectionsCantBeDeleted,
      onOkConfirmDeleteMultiple: async () => {
        const connections: {
          dataAgentConnectionIDs: number[];
          dataAgentNames: string[];
        } = {
          dataAgentConnectionIDs: [],
          dataAgentNames: [],
        };
        deletableDataAgentConnections.forEach(dataAgent => {
          connections.dataAgentConnectionIDs.push(dataAgent.id);
          connections.dataAgentNames.push(dataAgent.name);
        });
        await mutateDeleteDataAgentConnection(connections);
        setSelectedRows([]);
      },
    });
  }

  return (
    <>
      <Table
        key="data-agent-connections-table"
        className="data-agent-connections-table"
        columns={columns}
        size="small"
        dataSource={dataAgentConnections}
        title={() => (
          <article className="data-agent-connections-table__title">
            <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.title" />
            <section className="data-agent-connections-table__header-actions">
              {selectedRows.length ? (
                <>
                  <article className="data-agent-connections-table__header-actions-selection">
                    {selectedRows.length} Selected{' '}
                    <CloseOutlined
                      className="data-agent-connections-table__header-actions-close"
                      onClick={deselectAllRows}
                    />
                  </article>
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={deleteSelectedRows}
                    loading={isDeleteDataAgentConnectionLoading}
                    className="data-agent-connections-table__header-actions-deleted-selected"
                    type="text"
                    size="small"
                  />
                </>
              ) : (
                <>
                  <Tooltip
                    title={
                      <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.refetchData" />
                    }
                  >
                    <RedoOutlined
                      onClick={handleRefetchDataAgentConnections}
                      className="data-agent-connections-table__header-actions-refetch"
                      spin={isDataAgentConnectionsRefetching}
                      disabled={isDataAgentConnectionsLoading}
                    />
                  </Tooltip>
                  <Tooltip
                    title={
                      <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.addTooltip" />
                    }
                  >
                    <PlusOutlined onClick={openDataAgentConnectionModal} />
                  </Tooltip>
                </>
              )}
            </section>
          </article>
        )}
        locale={{
          emptyText: dataAgentConnectionsError ? (
            <ErrorResultTable
              subTitle={
                <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.errorWhileFetching" />
              }
              extra={[
                <Button
                  type="primary"
                  key="refetch"
                  onClick={() => {
                    resetSearchText();
                    handleRefetchDataAgentConnections();
                  }}
                >
                  Refresh
                </Button>,
              ]}
            />
          ) : (
            <EmptyTable
              description={
                <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.noDataAgents" />
              }
            >
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={openDataAgentConnectionModal}
              >
                New
              </Button>
            </EmptyTable>
          ),
        }}
        loading={isDataAgentConnectionsLoading}
        rowKey={dataAgentConnectionsFieldNames.id}
        pagination={getPaginationProps(
          dataAgentConnectionsTotals,
          newPage => {
            setPage(newPage - 1);
          },
          limit,
        )}
        rowSelection={getRowSelectionProps<DataAgentItem>(
          selectedRows.map(row => row.id),
          (selectedRowKeys: React.Key[], selectedRows: DataAgentItem[]) => {
            setSelectedRows(selectedRows);
          },
        )}
      />

      {showDataAgentConnectionModal && (
        <DataAgentConnectionModal
          instanceID={instanceID}
          clusterName={clusterName}
          showModal={showDataAgentConnectionModal}
          closeModal={closeDataAgentConnectionModal}
          {...(selectedDataAgentConnection?.name &&
          selectedDataAgentConnection.dataAgentID &&
          selectedDataAgentConnection.tenantID
            ? {
                dataAgentID: selectedDataAgentConnection.dataAgentID!,
                name: selectedDataAgentConnection?.name,
                tenantID: selectedDataAgentConnection?.tenantID,
                description: selectedDataAgentConnection?.description,
              }
            : {})}
        />
      )}
    </>
  );
}

export default DataAgentConnections;
