import './Configurations.less';

import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'antd';
import ConfigurationNavigationBar from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ConfigurationNavigationBar/ConfigurationNavigationBar';
import { Instance, ServerConfiguration, Service } from 'types/cluster';
import { SessionContext } from 'auth/SessionProvider';
import { InstancePlatform } from 'utils/platforms';
import useToggleFeature, { TOGGLE_FEATURE_KEYS } from 'hooks/useToggleFeature';
import useClusterConfiguration, {
  clusterConfigurationsType,
} from 'hooks/useClusterConfiguration';
import useBuildProperties, {
  BUILD_PROPERTIES_KEYS,
} from 'hooks/useBuildProperties';

interface ConfigurationsProps {
  instance: Instance;
  services: Service[];
  serverConfiguration: ServerConfiguration[];
  incortaXServerConfiguration: ServerConfiguration[];
  mlflowServerConfiguration: ServerConfiguration[];
}

function Configurations({
  instance,
  serverConfiguration,
  incortaXServerConfiguration,
  mlflowServerConfiguration,
  services,
}: ConfigurationsProps) {
  const { user } = useContext(SessionContext);

  const showClusterSize = !!user.configurations?.showInstanceSize.enabled;
  const showMicrosoftSynapse =
    user &&
    [InstancePlatform.AZURE, InstancePlatform.AZURE_SAAS].includes(
      instance.platform as InstancePlatform,
    );
  const isTrialUser = user?.role?.id === 1;
  const { isToggleFeatureEnabled: isIncortaXFeatureEnabled } = useToggleFeature(
    {
      user,
      featureKey: TOGGLE_FEATURE_KEYS.incortaX,
    },
  );
  const { isToggleFeatureEnabled: isCopilotEnabled } = useToggleFeature({
    user,
    featureKey: TOGGLE_FEATURE_KEYS.copilot,
  });

  const { isToggleFeatureEnabled: isMlflowEnabled } = useToggleFeature({
    user,
    featureKey: TOGGLE_FEATURE_KEYS.mlflow,
  });

  const mlflowPlatformAction = Boolean(
    user?.platformActions?.find(
      ({ name, platforms }) =>
        name === 'mlflow' && platforms.includes(instance.platform),
    ),
  );

  const copilotPlatformAction = Boolean(
    user?.platformActions?.find(
      ({ name, platforms }) =>
        name === 'copilot' && platforms.includes(instance.platform),
    ),
  );

  const showDeltaSharing = instance?.authorizedRoles?.some(
    role => role === 'owner' || role === 'accountAdmin',
  );

  const { buildPropertyValue: premiumPackageSupported } = useBuildProperties({
    instanceBuildProperties: instance.buildProperties!,
    buildPropertyKey: BUILD_PROPERTIES_KEYS['service.premium.supported'],
  });
  const clusterBuildSupportsPremium = premiumPackageSupported || false;

  const { clusterConfigurations } = useClusterConfiguration({
    showClusterSize,
    instance,
    services,
    user,
    isTrialUser,
    serverConfiguration,
    incortaXServerConfiguration,
    mlflowServerConfiguration,
    showDeltaSharing,
    showMicrosoftSynapse,
    showIncortaX: isIncortaXFeatureEnabled,
    isCopilotEnabled: isCopilotEnabled && copilotPlatformAction,
    isMlflowEnabled: isMlflowEnabled && mlflowPlatformAction,
  });

  // scroll to hash element
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const el = document.querySelector(hash);
      el?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isIncortaXFeatureEnabled]);

  return (
    <div className="Configurations">
      <Row gutter={32}>
        <Col xs={20}>
          {clusterConfigurations.map(
            (clusterConfig: clusterConfigurationsType) =>
              clusterConfig.conditionToShow && (
                <div
                  key={clusterConfig.id}
                  className="Configurations__section"
                  id={clusterConfig.id}
                >
                  <div className="Configurations__section-content">
                    {clusterConfig.component({
                      instance,
                      services,
                      user,
                      title: clusterConfig.title,
                      clusterBuildSupportsPremium,
                    })}
                  </div>
                </div>
              ),
          )}
        </Col>

        <Col xs={4}>
          <ConfigurationNavigationBar
            clusterConfigurations={clusterConfigurations}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Configurations;
