import React from 'react';
import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FormattedMessage } from 'react-intl';
import { DataAgentItem, SelectedDataAgent } from 'types/dataAgent.types';
import { useClusterTenants } from 'hooks/cluster';
import DataAgentVersionCellRenderer from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterDataAgentEdit/DataAgentConnections/cellRenderers/DataAgentVersionCellRenderer/DataAgentVersionCellRenderer';
import DataAgentStateCellRenderer from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterDataAgentEdit/DataAgentConnections/cellRenderers/DataAgentStateCellRenderer/DataAgentStateCellRenderer';
import DataAgentRowActions from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterDataAgentEdit/DataAgentConnections/DataAgentRowActions/DataAgentRowActions';
import { getColumnSearchProps } from 'components/reusables/antdReusables/antdFunctions';

const dataAgentConnectionsFieldNames = {
  id: 'id',
  name: 'name',
  description: 'description',
  tenant: 'tenant',
  version: 'version',
  status: 'status',
} as const;

type Props = {
  userId: string;
  instanceID: string;
  clusterName: string;
  setSelectedDataAgentConnection: React.Dispatch<
    React.SetStateAction<SelectedDataAgent | null | undefined>
  >;
  openDataAgentConnectionModal: () => void;
  searchText: string;
  updateSearchTextWithValue: (value: string) => void;
  resetSearchText: () => void;
};
function useDataAgentConnectionsColumns({
  userId,
  instanceID,
  clusterName,
  setSelectedDataAgentConnection,
  openDataAgentConnectionModal,
  searchText,
  updateSearchTextWithValue,
  resetSearchText,
}: Props): {
  dataAgentConnectionsFieldNames: Record<string, string>;
  columns: ColumnsType<DataAgentItem>;
} {
  const { data: clusterTenants } = useClusterTenants({
    instanceID,
    userId: userId,
    clusterName,
  });

  function handleSearch(
    selectedKeys: string[],
    confirm: (param?: any) => void,
  ) {
    confirm();
    updateSearchTextWithValue(selectedKeys[0]);
  }

  function handleReset(clearFilters?: () => void) {
    clearFilters?.();
    resetSearchText();
  }

  const searchedColumn = dataAgentConnectionsFieldNames.name;

  const columns: ColumnsType<DataAgentItem> = [
    {
      dataIndex: dataAgentConnectionsFieldNames.name,
      key: 'name',
      title: (
        <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.column.name" />
      ),
      ...getColumnSearchProps<DataAgentItem>(
        dataAgentConnectionsFieldNames.name,
        searchedColumn,
        searchText,
        handleSearch,
        handleReset,
      ),
    },
    {
      dataIndex: dataAgentConnectionsFieldNames.description,
      key: 'description',
      title: (
        <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.column.description" />
      ),
      ellipsis: {
        showTitle: false,
      },
      render: value => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      key: 'tenant',
      title: (
        <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.column.tenant" />
      ),
      render(value, { tenantId }) {
        return (
          clusterTenants?.find(tenant => tenant.id === tenantId)?.name || ''
        );
      },
    },
    {
      key: 'version',
      title: (
        <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.column.version" />
      ),
      render(value, { dataAgentAndControllerVersionResponse }) {
        return (
          <DataAgentVersionCellRenderer
            dataAgentAndControllerVersionResponse={
              dataAgentAndControllerVersionResponse
            }
          />
        );
      },
    },
    {
      dataIndex: dataAgentConnectionsFieldNames.status,
      key: 'status',
      width: 130,
      title: (
        <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.column.status" />
      ),
      render(
        value,
        {
          id,
          status,
          hasAuthFile,
          dataAgentConnectivityStatusResponse: dataAgentResponse,
          dataAgentControllerConnectivityStatusResponse: controllerResponse,
          error: dataAgentError,
        },
      ) {
        return (
          <DataAgentStateCellRenderer
            id={id}
            status={status}
            hasAuthFile={hasAuthFile}
            dataAgentResponse={dataAgentResponse}
            controllerResponse={controllerResponse}
            dataAgentError={dataAgentError}
          />
        );
      },
    },
    {
      key: 'actions',
      title: '',
      align: 'right',
      width: 250,
      render(
        value,
        {
          id,
          name,
          status,
          description,
          tenantId,
          hasAuthFile,
          dataSources,
          dataAgentConnectivityStatusResponse: dataAgentResponse,
          dataAgentControllerConnectivityStatusResponse: controllerResponse,
        },
        index,
      ) {
        const tenantName =
          clusterTenants?.find(tenant => tenant.id === tenantId)?.name || '';

        function handleEditRow() {
          setSelectedDataAgentConnection({
            dataAgentID: id,
            name,
            tenantID: tenantId,
            description,
          });
          openDataAgentConnectionModal();
        }

        return (
          <DataAgentRowActions
            status={status}
            instanceID={instanceID}
            dataAgentName={name}
            dataAgentID={id}
            tenantName={tenantName}
            hasAuthFile={hasAuthFile}
            dataSources={dataSources}
            dataAgentResponse={dataAgentResponse}
            controllerResponse={controllerResponse}
            handleEditRow={handleEditRow}
          />
        );
      },
    },
  ];

  return {
    dataAgentConnectionsFieldNames,
    columns,
  };
}

export default useDataAgentConnectionsColumns;
