import { InstanceBuildProperties } from 'types/cluster';

export enum BUILD_PROPERTIES_KEYS {
  'service.premium.supported' = 'service.premium.supported',
}

interface useBuildPropertiesProps {
  instanceBuildProperties: InstanceBuildProperties;
  buildPropertyKey: BUILD_PROPERTIES_KEYS;
}
function useBuildProperties({
  instanceBuildProperties,
  buildPropertyKey,
}: useBuildPropertiesProps) {
  let buildPropertyValue: string | boolean = false;
  if (instanceBuildProperties && buildPropertyKey in instanceBuildProperties) {
    buildPropertyValue = instanceBuildProperties[buildPropertyKey];
  }

  return { buildPropertyValue, BUILD_PROPERTIES_KEYS };
}

export default useBuildProperties;
