import React from 'react';
import './PythonPackagesEdit.less';
import Icon from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ReactComponent as BoxIcon } from 'images/box.svg';
import { ReactComponent as ArrowRightIcon } from 'images/arrow-right.svg';

interface PythonPackagesEditProps {
  clusterName: string;
}

function PythonPackagesEdit({ clusterName }: PythonPackagesEditProps) {
  return (
    <div className="ClusterEdit PythonPackagesEdit">
      <div className="ClusterEdit__icon">
        <Icon component={BoxIcon} />
      </div>
      <div className="ClusterEdit__content">
        <h3 className="ClusterEdit__title">
          <FormattedMessage id="clusterConfiguration.titles.pythonPackages" />
        </h3>
        <div className="PythonPackagesEdit__body">
          <p className="PythonPackagesEdit__hint">
            <FormattedMessage id="clusterConfiguration.pythonPackages.subHeader" />
          </p>
          <section className="PythonPackagesEdit__tab-link">
            <Icon component={ArrowRightIcon} />
            <Link to={`/clusters/${clusterName}?tab=libraries`}>Libraries</Link>
          </section>
        </div>
      </div>
    </div>
  );
}

export default PythonPackagesEdit;
