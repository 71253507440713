import React, { useEffect, useMemo, useState } from 'react';
import './ClusterTypeFieldSlider.less';
import { Slider } from 'antd';
import { useIntl } from 'react-intl';
import { SizeResultItem, SizesResult } from 'types/cluster';
import {
  getCSizeDetails,
  getClusterTypeIcons,
  getSizeValueFromSliderValue,
} from 'utils/cluster';
import { InstancePlatform } from 'utils/platforms';
import ClusterTypeFieldSliderMark from 'components/ClusterCreate/ClusterTypeField/ClusterTypeFieldSliderMark/ClusterTypeFieldSliderMark';

interface ClusterTypeFieldSliderProps {
  sizes: SizesResult['sizes'];
  editableSizes: SizesResult['allSizes'];
  currentSize?: string;
  onChange?: (value: SizeResultItem) => void;
  hideMainIcon?: boolean;
  currentPlatform?: InstancePlatform;
  isEditCluster?: boolean;
}

function ClusterTypeFieldSlider({
  sizes = [],
  editableSizes = [],
  currentSize,
  onChange = () => {},
  hideMainIcon = false,
  currentPlatform = InstancePlatform.GC,
  isEditCluster = false,
}: ClusterTypeFieldSliderProps) {
  const intl = useIntl();
  const sliderSizes = useMemo(
    () =>
      sizes.map((size, i) => {
        const isDisabled =
          isEditCluster &&
          !editableSizes.find(editableSize => {
            return editableSize.name === size.name;
          });
        const currentSizeInEditableSizes = editableSizes?.find(
          editableSize => editableSize.id === size.id,
        );
        const isSliderValueRequireSupport =
          isEditCluster && !currentSizeInEditableSizes ? true : false;
        return {
          ...size,
          sliderValue: Math.round((i * 100) / (sizes.length - 1)),
          ...(isEditCluster && { isDisabled, isSliderValueRequireSupport }),
          tooltipText: isDisabled
            ? isSliderValueRequireSupport
              ? intl.formatMessage({
                  id: 'clusterConfiguration.configurationsTab.disabledClusterSize',
                })
              : intl.formatMessage({
                  id: 'clusterConfiguration.configurationsTab.upgradeRequired',
                })
            : size.displayName,
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sizes, editableSizes],
  );
  const sliderValues = sliderSizes.map(size => size.sliderValue);

  const [sliderValue, setSliderValue] = useState(() => {
    if (currentSize) {
      const size = sliderSizes.find(size => size.name === currentSize);
      return size?.sliderValue ?? 0;
    } else {
      return 0;
    }
  });
  const sizeValue = getSizeValueFromSliderValue(sliderValues, sliderValue);

  useEffect(() => {
    const size = sliderSizes.find(size => size.name === currentSize);
    setSliderValue(size?.sliderValue ?? 0);
  }, [sliderSizes, currentSize]);

  const marks: Record<number, React.ReactNode> = {};
  for (const size of sliderSizes) {
    // create sizes label
    marks[size.sliderValue] = (
      <ClusterTypeFieldSliderMark
        size={size}
        isTooltipOff={
          'isDisabled' in size && size.isDisabled ? size.isDisabled : false
        }
        tooltipText={size.tooltipText}
      />
    );
  }

  const selectedSize = sliderSizes.find(size => size.sliderValue === sizeValue);

  return (
    <div className="ClusterTypeFieldSlider">
      {!hideMainIcon && selectedSize && (
        <div className="ClusterTypeFieldSlider__selected-size">
          {React.createElement(getClusterTypeIcons(selectedSize.name, 'fill'), {
            style: { fontSize: 35, color: '#99A6B0' },
          })}

          <div className="ClusterTypeFieldSlider__selected-text">
            {selectedSize.displayName}
          </div>
          <div className="ClusterTypeFieldSlider__selected-desc">
            {getCSizeDetails(selectedSize)}
          </div>
        </div>
      )}

      <Slider
        marks={marks}
        tooltipVisible={false}
        included={false}
        value={sliderValue}
        onChange={(num: number) => {
          const closestMarkValue = getSizeValueFromSliderValue(
            sliderValues,
            num,
          );

          const currentSliderSize = sliderSizes.find(
            size => size.sliderValue === closestMarkValue,
          );
          const isSizeDisabled =
            currentSliderSize && 'isDisabled' in currentSliderSize
              ? currentSliderSize.isDisabled
              : false;

          if (isEditCluster && isSizeDisabled) {
            return;
          }
          return setSliderValue(closestMarkValue as number);
        }}
        onAfterChange={() => {
          if (selectedSize?.id) {
            onChange(selectedSize);
          }
        }}
        className="ClusterTypeFieldSlider__slider"
        disabled={currentPlatform === InstancePlatform.AZURE}
      />
    </div>
  );
}

export default React.forwardRef(ClusterTypeFieldSlider);
