import React, { useState } from 'react';
import './DataAgentSetupStepsModalContent.less';
import {
  CheckCircleFilled,
  DownloadOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { copyText } from 'helpers/clipboard';

type DataAgentSetupStepsModalContentProps = {
  downloadLink: string;
  dataAgentNewUI: boolean;
  analyticsDataAgent?: string;
  loaderDataAgent?: string;
  cmcDataAgent?: string;
};
function DataAgentSetupStepsModalContent({
  downloadLink,
  dataAgentNewUI,
  analyticsDataAgent,
  loaderDataAgent,
  cmcDataAgent,
}: DataAgentSetupStepsModalContentProps) {
  async function handleCopy(
    setShowCopiedState: React.Dispatch<React.SetStateAction<boolean>>,
    textToCopy: string,
  ) {
    copyText({ textToCopy });
    setShowCopiedState(true);
    setTimeout(() => {
      setShowCopiedState(false);
    }, 3000);
  }

  const [showCopiedStateForAnalytics, setShowCopiedStateForAnalytics] =
    useState(false);
  const [showCopiedStateForLoader, setShowCopiedStateForLoader] =
    useState(false);
  const [showCopiedStateForCMC, setShowCopiedStateForCMC] = useState(false);

  return (
    <section className="data-agent-setup-steps-modal-content__wrapper">
      <p>Follow the step below to set up a data agent.</p>
      <ol>
        <li>
          <h2>Download Data Agent</h2>
          <p>
            Download Data Agent runnable JAR file. The data agent is a zip file
            that must be unzipped to the local directory where your on-premise
            data sources are located. <br />* The local network must not block
            outgoing connections.
          </p>
          <a download href={downloadLink} className="download-link link">
            <DownloadOutlined /> Download Data Agent
          </a>{' '}
        </li>
        <li>
          <h2>Define & Authorize Data Agent</h2>
          {!dataAgentNewUI ? (
            <>{`Create a new Data Agent from Incorta application by going to Data > Data Agents and select New > Data Agent. After creating a data agent you will be prompted to generate an authentication file. Once .auth file generated and downloaded to your machine, place the file in the conf directory inside the data agent.`}</>
          ) : (
            <>
              <p>
                {`Create a new data agent from the Cloud Portal. After creating a data agent you will be prompted to generate an authentication file. Once the .auth file is generated and downloaded to your machine, copy it to the following directories: `}
              </p>
              <ul>
                <li>
                  <label>
                    <strong>incorta.dataagent/incorta.dataagent/conf/</strong>
                    {` and `}
                  </label>
                </li>
                <li>
                  <label>
                    <strong>
                      incorta.dataagent/incorta.dataagent.controller/conf/
                    </strong>
                    {` directories.`}
                  </label>
                </li>
              </ul>
            </>
          )}
        </li>
        {dataAgentNewUI && analyticsDataAgent && loaderDataAgent && (
          <li>
            <h2>Whitelist Analytics & Loader hosts and ports</h2>
            <p>Add the following public host and ports to your whitelist:</p>
            <ul className="cluster-data-agent-edit__whitelist-ul">
              <li>
                <section className="cluster-data-agent-edit__service">
                  <label>Analytics Public Hosts and Ports: </label>
                  <span
                    className="cluster-data-agent-edit__service-link"
                    onClick={() =>
                      handleCopy(
                        setShowCopiedStateForAnalytics,
                        analyticsDataAgent,
                      )
                    }
                  >
                    {analyticsDataAgent}{' '}
                    {showCopiedStateForAnalytics ? (
                      <span className="cluster-data-agent-edit__service-link-copy-icon copied">
                        <CheckCircleFilled />
                        Copied
                      </span>
                    ) : (
                      <LinkOutlined className="cluster-data-agent-edit__service-link-copy-icon" />
                    )}
                  </span>
                </section>
              </li>
              <li>
                <section className="cluster-data-agent-edit__service">
                  <label>Loader Public Hosts and Ports: </label>
                  <span
                    className="cluster-data-agent-edit__service-link"
                    onClick={() =>
                      handleCopy(setShowCopiedStateForLoader, loaderDataAgent)
                    }
                  >
                    {loaderDataAgent}{' '}
                    {showCopiedStateForLoader ? (
                      <span className="cluster-data-agent-edit__service-link-copy-icon copied">
                        <CheckCircleFilled />
                        Copied
                      </span>
                    ) : (
                      <LinkOutlined className="cluster-data-agent-edit__service-link-copy-icon" />
                    )}
                  </span>
                </section>
              </li>
              {!!cmcDataAgent && (
                <li>
                  <section className="cluster-data-agent-edit__service">
                    <label>CMC Public Hosts and Ports: </label>
                    <span
                      className="cluster-data-agent-edit__service-link"
                      onClick={() =>
                        handleCopy(setShowCopiedStateForCMC, cmcDataAgent)
                      }
                    >
                      {cmcDataAgent}{' '}
                      {showCopiedStateForCMC ? (
                        <span className="cluster-data-agent-edit__service-link-copy-icon copied">
                          <CheckCircleFilled />
                          Copied
                        </span>
                      ) : (
                        <LinkOutlined className="cluster-data-agent-edit__service-link-copy-icon" />
                      )}
                    </span>
                  </section>
                </li>
              )}
            </ul>
          </li>
        )}
        {!dataAgentNewUI ? (
          <li>
            <h2>Start Data Agent Service</h2>
            <p>
              {`Start the data agent by running “./agent.sh start” on Unix/Linux, or “agent.bat start” on Windows from inside the data agent directory. Once the data agent is started, it should connect automatically to Incorta. You can check the data agent status in Incorta on Data Agents tab under data. Data > Data Agent.`}
            </p>
          </li>
        ) : (
          <>
            <li>
              <h2>Start the Data Agent Controller</h2>
              <p>
                {`On your machine in the `}
                <strong>incorta.dataagent/incorta.dataagent.controller</strong>
                {` directory, run one of the following scripts depending on the host machine’s OS: `}
              </p>
              <ul>
                <li>
                  <label>
                    <strong>bin/controller.sh start</strong>
                    {` for Linux or `}
                  </label>
                </li>
                <li>
                  <label>
                    <strong>{`bin/controller.bat start`}</strong>
                    {` for Windows.`}
                  </label>
                </li>
              </ul>
            </li>
            <li>
              <h2>Start the Data Agent</h2>
              <p>
                {`Start the data agent from the Cloud Portal by clicking `}
                <strong>Start</strong>
                {`. Once the data agent starts, it automatically connects to Incorta.`}
              </p>
            </li>
          </>
        )}

        <li>
          <h2>Connect & Map Data Sources</h2>
          <p>
            {`Once a data agent is created, authorized, and connected, you can use it to create data sources. The connection between Incorta and the data agent is encrypted via SSL, and both sides are authenticated via SSL certificates.`}
          </p>
        </li>
      </ol>

      <label className="cluster-data-agent-edit__hint">
        For more information, visit the{' '}
        <a
          href="https://docs.incorta.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          documentation
        </a>{' '}
        on Data Agent.
      </label>
    </section>
  );
}

export default DataAgentSetupStepsModalContent;
