import React, { useRef } from 'react';
import './DataAgentRowActions.less';
import {
  EllipsisOutlined,
  MinusCircleOutlined,
  PlayCircleOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import { Modal, Dropdown, Menu, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import TableRowActionButton from 'components/reusables/TableRowActionButton/TableRowActionButton';
import {
  DataAgentComputedStatus,
  DataAgentConnectionAction,
  DataAgentConnectivityStatusResponse,
  DataAgentControllerConnectivityStatusResponse,
  DataAgentDataSource,
  DataAgentStatus,
} from 'types/dataAgent.types';
import {
  useDeleteDataAgentConnection,
  useExecuteActionOnDataAgentConnection,
  useGenerateDataAgentConnectionAuthneticationFile,
} from 'hooks/dataAgent';
import useDataAgentHelper from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterDataAgentEdit/useDataAgentHelper';
import useDataAgentDeleteModal from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterDataAgentEdit/DataAgentConnections/useDataAgentDeleteModal/useDataAgentDeleteModal';

function DataAgentRowActions({
  status,
  instanceID,
  dataAgentName,
  dataAgentID,
  tenantName,
  hasAuthFile,
  dataSources,
  dataAgentResponse,
  controllerResponse,
  handleEditRow,
}: {
  status: DataAgentStatus;
  instanceID: string;
  dataAgentName: string;
  dataAgentID: number;
  tenantName: string;
  hasAuthFile: boolean;
  dataSources: DataAgentDataSource[];
  dataAgentResponse: DataAgentConnectivityStatusResponse;
  controllerResponse: DataAgentControllerConnectivityStatusResponse;
  handleEditRow: () => void;
}) {
  const intl = useIntl();

  const actionRef = useRef<DataAgentConnectionAction>();

  const { showDeleteModal } = useDataAgentDeleteModal();

  const { getDataAgentComputedState } = useDataAgentHelper();
  const dataAgentComputedStatus = getDataAgentComputedState({
    dataAgentResponse,
    controllerResponse,
    hasAuthFile,
  });

  const { mutateAsync: mutateDeleteDataAgentConnection } =
    useDeleteDataAgentConnection({ instanceID });

  const {
    mutateAsync: mutateCreateDataAgentConnectionAuthneticationFile,
    isLoading: isCreateDataAgentConnectionAuthneticationFileLoading,
  } = useGenerateDataAgentConnectionAuthneticationFile({
    instanceID,
    tenantName,
    dataAgentID,
    dataAgentName,
  });
  async function handleGenerateAuthenticationFile() {
    await mutateCreateDataAgentConnectionAuthneticationFile();
  }
  async function handleRegenerateAuthenticationFile() {
    Modal.confirm({
      title: regenerateAuthenticationFileConfirmModalMessages.title,
      content: regenerateAuthenticationFileConfirmModalMessages.content,
      okText: regenerateAuthenticationFileConfirmModalMessages.submitButton,
      centered: true,
      async onOk() {
        await mutateCreateDataAgentConnectionAuthneticationFile();
      },
    });
  }

  const {
    mutateAsync: mutateExecuteActionOnDataAgentConnection,
    isLoading: isExecuteActionOnDataAgentConnectionLoading,
  } = useExecuteActionOnDataAgentConnection({
    instanceID,
    dataAgentID,
    dataAgentName,
    tenantName,
  });

  const showStartAction = [DataAgentComputedStatus.STOPPED].includes(
    dataAgentComputedStatus,
  );
  const showStopAction = [
    DataAgentComputedStatus.ALL_DA_AND_CONTROLLER_CONNECTED,
    DataAgentComputedStatus.CONTROLLER_DISCONNECTED,
    DataAgentComputedStatus.DATA_AGENT_STARTED_BUT_NOT_CONNECTED_TO_ALL_SERVICES,
    DataAgentComputedStatus.BOTH_DA_AND_CONTROLLER_UNREACHABLE_WITH_DA_CONNECTED_SERVICES,
  ].includes(dataAgentComputedStatus);
  const showRestartAction = [
    DataAgentComputedStatus.ALL_DA_AND_CONTROLLER_CONNECTED,
    DataAgentComputedStatus.CONTROLLER_DISCONNECTED,
    DataAgentComputedStatus.DATA_AGENT_STARTED_BUT_NOT_CONNECTED_TO_ALL_SERVICES,
    DataAgentComputedStatus.BOTH_DA_AND_CONTROLLER_UNREACHABLE_WITH_DA_CONNECTED_SERVICES,
  ].includes(dataAgentComputedStatus);

  const isStopActionDisabled =
    [
      DataAgentComputedStatus.CONTROLLER_DISCONNECTED,
      DataAgentComputedStatus.BOTH_DA_AND_CONTROLLER_UNREACHABLE_WITH_DA_CONNECTED_SERVICES,
    ].includes(dataAgentComputedStatus) ||
    (isExecuteActionOnDataAgentConnectionLoading &&
      actionRef.current !== DataAgentConnectionAction.STOP);

  const isRestartActionDsisabled =
    [
      DataAgentComputedStatus.CONTROLLER_DISCONNECTED,
      DataAgentComputedStatus.BOTH_DA_AND_CONTROLLER_UNREACHABLE_WITH_DA_CONNECTED_SERVICES,
    ].includes(dataAgentComputedStatus) ||
    (isExecuteActionOnDataAgentConnectionLoading &&
      actionRef.current !== DataAgentConnectionAction.RESTART);

  async function handleStartDataAgentConnection() {
    actionRef.current = DataAgentConnectionAction.START;
    await mutateExecuteActionOnDataAgentConnection({
      action: DataAgentConnectionAction.START,
    });
  }

  async function handleStopDataAgentConnection() {
    actionRef.current = DataAgentConnectionAction.STOP;
    await mutateExecuteActionOnDataAgentConnection({
      action: DataAgentConnectionAction.STOP,
    });
  }
  async function handleRestartDataAgentConnection() {
    actionRef.current = DataAgentConnectionAction.RESTART;
    await mutateExecuteActionOnDataAgentConnection({
      action: DataAgentConnectionAction.RESTART,
    });
  }

  const regenerateAuthenticationFileConfirmModalMessages = {
    title: intl.formatMessage(
      {
        id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.regenerateAuthenticationFileConfirm.title',
      },
      {
        dataAgentName: <strong>{dataAgentName}</strong>,
      },
    ),
    content: intl.formatMessage(
      {
        id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.regenerateAuthenticationFileConfirm.content',
      },
      { dataAgentName },
    ),
    submitButton: intl.formatMessage({
      id: 'clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionModal.regenerateAuthenticationFileConfirm.submitButton',
    }),
  };

  const menu = (
    <Menu className="data-agent-row-actions__menu">
      <Menu.Item key="edit" onClick={handleEditRow}>
        <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.menuAction.edit" />
      </Menu.Item>
      {status !== DataAgentStatus.NO_AUTHENTICATION_FILE_GENERATED && (
        <Menu.Item
          key="regenerateAuthenticationFile"
          onClick={handleRegenerateAuthenticationFile}
        >
          <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.menuAction.regenerateAuthenticationFile" />
        </Menu.Item>
      )}
      <Menu.Item
        key="delete"
        onClick={() => {
          showDeleteModal({
            isMultipleDataAgents: false,
            isDataAgentConnectionCantBeDeleted: !!dataSources?.length,
            dataAgentName,
            dataSources,
            onOkConfirmDeleteSingle: async () => {
              await mutateDeleteDataAgentConnection({
                dataAgentConnectionIDs: [dataAgentID],
                dataAgentNames: [dataAgentName],
              });
            },
          });
        }}
      >
        <Tooltip
          title={
            <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.tooltipCantAddForTrialUser" />
          }
        >
          <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.menuAction.delete" />
        </Tooltip>
      </Menu.Item>
    </Menu>
  );

  return (
    <section className="data-agent-row-actions__wrapper">
      {status === DataAgentStatus.NO_AUTHENTICATION_FILE_GENERATED ? (
        <TableRowActionButton
          onClick={handleGenerateAuthenticationFile}
          loading={isCreateDataAgentConnectionAuthneticationFileLoading}
        >
          <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.rowAction.generateAuthenticationFile" />
        </TableRowActionButton>
      ) : (
        <>
          {showStartAction && (
            <TableRowActionButton
              icon={<PlayCircleOutlined />}
              onClick={handleStartDataAgentConnection}
              loading={
                actionRef.current === DataAgentConnectionAction.START &&
                isExecuteActionOnDataAgentConnectionLoading
              }
            >
              <FormattedMessage
                tagName="span"
                id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.rowAction.start"
              />
            </TableRowActionButton>
          )}

          {showStopAction && (
            <TableRowActionButton
              icon={<MinusCircleOutlined />}
              onClick={handleStopDataAgentConnection}
              disabled={isStopActionDisabled}
              loading={
                isExecuteActionOnDataAgentConnectionLoading &&
                actionRef.current === DataAgentConnectionAction.STOP
              }
            >
              <FormattedMessage
                tagName="span"
                id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.rowAction.stop"
              />
            </TableRowActionButton>
          )}

          {showRestartAction && (
            <TableRowActionButton
              icon={<RetweetOutlined />}
              onClick={handleRestartDataAgentConnection}
              disabled={isRestartActionDsisabled}
              loading={
                isExecuteActionOnDataAgentConnectionLoading &&
                actionRef.current === DataAgentConnectionAction.RESTART
              }
            >
              <FormattedMessage
                tagName="span"
                id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.rowAction.restart"
              />
            </TableRowActionButton>
          )}
        </>
      )}
      <Dropdown
        className="data-agent-row-actions__dropdown"
        trigger={['click']}
        overlay={menu}
      >
        <EllipsisOutlined />
      </Dropdown>
    </section>
  );
}

export default DataAgentRowActions;
