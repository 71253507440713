import React from 'react';
import './PremiumClusterUpgrade.less';
import { Button, Modal } from 'antd';
import { CrownOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

function PremiumClusterUpgrade() {
  const intl = useIntl();

  function confirmUpgrageIncortaPremium() {
    Modal.confirm({
      title: (
        <article className="premium-cluster-upgrade-modal__title">
          {intl.formatMessage({
            id: 'clusterConfiguration.mainTab.premium.upgradeToPremiumModal.title',
          })}
        </article>
      ),
      content: (
        <section className="premium-cluster-upgrade-modal__content">
          <p>
            {intl.formatMessage({
              id: 'clusterConfiguration.mainTab.premium.upgradeToPremiumModal.contentIntro',
            })}
            <a
              href={'https://docs.incorta.com/latest/incorta-premium'}
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage({
                id: 'clusterConfiguration.mainTab.premium.upgradeToPremiumModal.contentLearnMore',
              })}
            </a>
          </p>

          <strong>
            {intl.formatMessage({
              id: 'clusterConfiguration.mainTab.premium.upgradeToPremiumModal.note',
            })}
          </strong>
          <label>
            {intl.formatMessage({
              id: 'clusterConfiguration.mainTab.premium.upgradeToPremiumModal.contentOutro',
            })}
          </label>
        </section>
      ),
      okButtonProps: {
        hidden: true,
      },
      cancelText: intl.formatMessage({
        id: 'clusterConfiguration.mainTab.premium.upgradeToPremiumModal.cancel',
      }),
    });
  }

  return (
    <Button
      size="small"
      className="premium-cluster-upgrade-button"
      icon={<CrownOutlined />}
      onClick={confirmUpgrageIncortaPremium}
    >
      {intl.formatMessage({
        id: 'clusterConfiguration.mainTab.premium.upgradeToPremium',
      })}
    </Button>
  );
}

export default PremiumClusterUpgrade;
