import React, { Key } from 'react';
import { ColumnType } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import {
  FilterDropdownProps,
  TablePaginationConfig,
  TableRowSelection,
} from 'antd/lib/table/interface';
import HighlightedSearchedText from 'components/reusables/HighlightedSearchedText/HighlightedSearchedText';
import DebouncedInput from 'components/reusables/DebouncedInput/DebouncedInput';

/**
 * Method that add search property for table columns
 * @param dataIndex
 * @param searchedColumn
 * @param searchText
 * @param handleSearch
 * @param handleReset can be invoked as `handleReset(clearFilters)`
 * @returns `ColumnType`
 */
function getColumnSearchProps<T>(
  dataIndex: keyof T,
  searchedColumn: string,
  searchText: string,
  handleSearch: (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
  ) => void,
  handleReset: (clearFilters?: () => void) => void,
): ColumnType<T> {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <DebouncedInput
          value={selectedKeys[0] ?? ''}
          onChange={async (
            value: string | number | React.ChangeEvent<HTMLInputElement>,
          ) => {
            if (!selectedKeys.length && !value) {
              return;
            }
            setSelectedKeys(String(value) ? [String(value)] : []);
            handleSearch(String(value) ? [String(value)] : [], confirm);
          }}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
          size="small"
          allowClear
          placeholder={`Search ${dataIndex.toString()}`}
          style={{
            width: 200,
            height: 24,
            display: 'flex',
          }}
        />
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    render: text => {
      return searchedColumn === dataIndex ? (
        <HighlightedSearchedText
          textToHighlight={text ? text.toString() : ''}
          searchWord={searchText}
        />
      ) : (
        text
      );
    },
  };
}

function getPaginationProps(
  total: number | undefined,
  handleOnChange: (newPage: number, pageSize?: number) => unknown,
  limit = 10,
): TablePaginationConfig {
  return {
    pageSize: limit,
    total,
    showSizeChanger: false,
    onChange(newPage: number, pageSize?: number) {
      handleOnChange(newPage);
    },
  };
}

function getRowSelectionProps<T>(
  selectedRowKeys: Key[],
  handleOnChange: (selectedRowKeys: Key[], selectedRows: T[]) => unknown,
): TableRowSelection<T> {
  return {
    selectedRowKeys,
    onChange: (selectedRowKeys: Key[], selectedRows: T[]) => {
      handleOnChange(selectedRowKeys, selectedRows);
    },
  };
}

export { getColumnSearchProps, getPaginationProps, getRowSelectionProps };
