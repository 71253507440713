import { notification, Switch, Tooltip } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  useFetchAllowPremiumPackage,
  useMutateCopilotState,
} from 'hooks/cluster';
import useBuildProperties, {
  BUILD_PROPERTIES_KEYS,
} from 'hooks/useBuildProperties';
import { ReactComponent as CopilotLogo } from 'images/copilot-filled.svg';

import { Instance, Service } from 'types/cluster';
import { getClusterStatus } from 'utils/cluster';
interface ClusterConnectCopilot {
  instance: Instance;
  services: Service[];
  title: string | JSX.Element;
}

function Copilot({ instance, services, title }: ClusterConnectCopilot) {
  const {
    mutateAsync: mutateCopilotState,
    isLoading: isCopilotSettingLoading,
  } = useMutateCopilotState({
    clusterID: instance.id,
  });

  async function handleChange(enable: boolean) {
    const { data } = await mutateCopilotState({ enable });
    notification.success({
      message: 'Success',
      description: data.message,
    });
  }

  const canUpdate = instance.clusterPolicy.clusterPolicy.includes('update');
  const status = getClusterStatus(instance, services[0]);

  const { data: premiumPackageFlag } = useFetchAllowPremiumPackage();
  const allowPremiumPackage = premiumPackageFlag?.enabled;

  const { buildPropertyValue: premiumPackageSupported } = useBuildProperties({
    instanceBuildProperties: instance.buildProperties,
    buildPropertyKey: BUILD_PROPERTIES_KEYS['service.premium.supported'],
  });
  const clusterBuildSupportsPremium = Boolean(premiumPackageSupported);
  const isClusterRunning = status === 'running';

  let disableSwitch = !isClusterRunning;
  const showPremiumOnlyTooltip =
    !instance.isPremium &&
    allowPremiumPackage &&
    clusterBuildSupportsPremium &&
    instance.premiumFeaturesConfig?.includes('INCORTA_COPILOT_ENABLEMENT');

  if (isClusterRunning && showPremiumOnlyTooltip) {
    disableSwitch = true;
  }

  return (
    <div className="ClusterEdit ClusterConnectBiToolsEdit">
      <div className="ClusterEdit__icon">
        <span>
          <CopilotLogo style={{ width: '16px', height: '16px' }} />
        </span>
      </div>
      <div className="ClusterEdit__content ClusterConnectBiToolsEdit__content">
        <div className="ClusterConnectBiToolsEdit__main">
          <h3 className="ClusterEdit__title">{title}</h3>
          <div className="ClusterConnectBiToolsEdit__body">
            <p className="ClusterConnectBiToolsEdit__hint">
              <FormattedMessage id="clusterConfiguration.copilot.desription" />
            </p>
          </div>
        </div>
        <div className="ClusterConnectBiToolsEdit__switch-button">
          {canUpdate && (
            <Tooltip
              title={
                !isClusterRunning ? (
                  <FormattedMessage id="clusterConfiguration.general.clusterMustBeConnectedToChange" />
                ) : showPremiumOnlyTooltip ? (
                  <FormattedMessage id="premiumPackage.premiumPackageOnlyFeature" />
                ) : (
                  ''
                )
              }
            >
              <Switch
                checked={instance.enableOpenAI}
                onChange={handleChange}
                loading={isCopilotSettingLoading}
                disabled={disableSwitch}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
}

export default Copilot;
