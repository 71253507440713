import { useMutation, useQuery } from 'react-query';
import { notification } from 'antd';
import { useContext, useState } from 'react';
import { LibrariesLocale } from '@incorta/spark-dependency-libraries';
import { QueryServerKeys } from 'constants/QueryServerKeys';
import {
  getLibraries,
  uninstallSingleLibrary,
  uninstallMultipleLibraries,
  getUploadFileURLFromGoogleStorage,
  installClusterPackageGeneric,
  syncPackages,
  installClusterPackages,
} from 'services/libraries';
import {
  LibrariesResponseItem,
  LibraryDataTypeMap,
} from 'types/libraries.types';
import { queryClient } from 'index';
import { getErrorMessage, showErrorMessage } from 'utils/errors';
import useLibrariesLogic from 'components/ClusterDetails/ClusterDetailsBody/Libraries/useLibrariesLogic';
import { SessionContext } from 'auth/SessionProvider';

const defaultRefetchInterval = 2 * (60 * 1000); // 2 mins
const quickRefetchInterval = 30 * 1000; // 30 seconds

export function useLibraries({
  instanceID,
  locale,
}: {
  instanceID: string;
  locale: LibrariesLocale;
}) {
  const { getAnyPodInstallingOrUninstalling } = useLibrariesLogic({
    instanceID,
    locale,
  });

  const [interval, setInterval] = useState(defaultRefetchInterval);

  const queryOptions = useQuery(
    [QueryServerKeys.LIBRARIES.GET_LIBRARIES, instanceID],
    async () => {
      const result = await getLibraries({ instanceID });
      return result.data.libraries;
    },
    {
      staleTime: interval, // 2 mins
      refetchInterval: interval, // 2 mins
      refetchIntervalInBackground: true,
      onSuccess(data) {
        const isAnyPodInstallingOrUninstalling = data.some(library => {
          return getAnyPodInstallingOrUninstalling({
            analyticsState: library.analyticsState,
            loaderState: library.loaderState,
            chidoriState: library.chidoriState,
            sparkState: library.sparkState,
          });
        });

        if (isAnyPodInstallingOrUninstalling) {
          setInterval(quickRefetchInterval);
        } else {
          setInterval(defaultRefetchInterval);
        }
      },
    },
  );

  return queryOptions;
}

export function useMutateInstallPackageGeneric<
  T extends keyof LibraryDataTypeMap,
>({ instanceID }: { instanceID: string }) {
  return useMutation(
    async ({ type, data }: { type: T; data: LibraryDataTypeMap[T] }) => {
      const res = await installClusterPackageGeneric({
        instanceID,
        type,
        data,
      });
      return res;
    },
    {
      onSuccess(res) {
        queryClient.invalidateQueries(QueryServerKeys.LIBRARIES.GET_LIBRARIES);
        notification.success({
          message: 'Success',
          description: res.data.message,
        });
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    },
  );
}

export function useMutateClusterInstallPackages({
  instanceID,
}: {
  instanceID: string;
}) {
  let queryOptions = useMutation(
    async (args: any) => {
      const res = await installClusterPackages({
        instanceID: instanceID,
        ...args,
      });
      return res;
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(QueryServerKeys.LIBRARIES.GET_LIBRARIES);
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}

export function useUninstallLibrary<T extends keyof LibraryDataTypeMap>({
  instanceID,
}: {
  instanceID: string;
}) {
  const queryOptions = useMutation(
    async ({ type, data }: { type: T; data: LibraryDataTypeMap[T] }) => {
      return await uninstallSingleLibrary<T>({ instanceID, type, data });
    },
    {
      mutationKey: [QueryServerKeys.LIBRARIES.UNINSTALL_LIBRARIES],
      async onSuccess(res) {
        queryClient.invalidateQueries(QueryServerKeys.LIBRARIES.GET_LIBRARIES);
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}

export function useUninstallMultipleLibrary({
  instanceID,
}: {
  instanceID: string;
}) {
  const queryOptions = useMutation(
    async ({
      librariesToUninstall,
    }: {
      librariesToUninstall: LibrariesResponseItem[];
    }) => {
      return await uninstallMultipleLibraries({
        instanceID,
        librariesToUninstall,
      });
    },
    {
      mutationKey: [QueryServerKeys.LIBRARIES.UNINSTALL_LIBRARIES],
      async onSuccess(res) {
        queryClient.invalidateQueries(QueryServerKeys.LIBRARIES.GET_LIBRARIES);
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}

export function useGetUploadFileURLFromGoogleStorage() {
  const queryOptions = useMutation(
    async ({
      instanceID,
      fileName,
    }: {
      instanceID: string;
      fileName: string;
    }) => {
      return await getUploadFileURLFromGoogleStorage({ instanceID, fileName });
    },
    {
      mutationKey: [QueryServerKeys.LIBRARIES.GET_UPLOAD_URL],
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}

export function useSyncPackages({
  instanceID,
  type,
}: {
  instanceID: string;
  type: string;
}) {
  const { user } = useContext(SessionContext);
  const queryOptions = useMutation(
    async () => {
      const res = await syncPackages({
        instanceID,
        type,
      });
      return res.status;
    },
    {
      mutationKey: [QueryServerKeys.CLUSTER.SYNC_PYTHON_PACKAGES, instanceID],
      async onSuccess() {
        await queryClient.invalidateQueries(
          [QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY, user.uuid],
          {
            refetchInactive: true,
            refetchActive: true,
          },
        );
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}
