import './ClusterDataAgentEdit.less';

import React, { useContext, useState } from 'react';
import Icon, { DownloadOutlined } from '@ant-design/icons';
import { Modal, notification, Switch, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as ShieldIcon } from 'images/shield.svg';
import { Instance, ServerConfiguration, Service } from 'types/cluster';
import { useMutateCluster } from 'hooks/cluster';
import { getClusterStatus } from 'utils/cluster';
import { SessionContext } from 'auth/SessionProvider';
import { API_URL } from 'utils/http';
import useToggleFeature, { TOGGLE_FEATURE_KEYS } from 'hooks/useToggleFeature';
import DataAgentSetupStepsModalContent from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterDataAgentEdit/DataAgentSetupStepsModalContent/DataAgentSetupStepsModalContent';
import DataAgentConnections from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterDataAgentEdit/DataAgentConnections/DataAgentConnections';
import DisconnectedClusterPlaceholder from 'components/DisconnectedClusterPlaceholder/DisconnectedClusterPlaceholder';

interface ClusterDataAgentEditProps {
  instance: Instance;
  services: Service[];
  serverConfigurations: ServerConfiguration[];
}

function ClusterDataAgentEdit({
  instance,
  services,
}: ClusterDataAgentEditProps) {
  const isVersionSupported = instance.dataAgentNewUI;
  const isClusterRunning = instance.status === 'running';

  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);
  const { user } = useContext(SessionContext);
  const downloadLink = useDownloadLink({ instance });

  const { mutateAsync: mutateCluster, isLoading } = useMutateCluster({
    clusterName: instance.name,
  });

  async function enableDataAgent() {
    const { data } = await mutateCluster({ enableDataAgent: true });
    setShowModal(true);
    notification.success({
      message: 'Success',
      description: data.message,
    });
  }
  async function handleChange(enable: boolean) {
    if (enable) {
      Modal.confirm({
        title: 'Enable Data Agent',
        content:
          'Are you sure you want to proceed with enabling the Data Agent?',
        okText: 'Proceed',
        centered: true,
        width: 620,
        async onOk() {
          enableDataAgent();
        },
      });
    } else {
      Modal.confirm({
        title: 'Are you sure you want to disable Data Agent?',
        content:
          'Disabling the data agent will block incorta from connecting to any data source using the data agent.',
        okText: 'Disable',
        centered: true,
        width: 620,
        async onOk() {
          const { data } = await mutateCluster({ enableDataAgent: false });
          notification.success({
            message: 'Success',
            description: data.message,
          });
        },
      });
    }
  }

  const canUpdate = instance.clusterPolicy.clusterPolicy.includes('update');

  const status = getClusterStatus(instance, services[0]);

  const enable = status === 'running';
  const { isToggleFeatureEnabled: isDataAgentToggleFeatureEnabled } =
    useToggleFeature({
      user,
      featureKey: TOGGLE_FEATURE_KEYS.dataagent,
    });

  const showDataAgent = user?.platformActions?.find(
    ({ name, platforms }) =>
      name === 'da' && platforms.includes(instance.platform),
  );

  const showDataAgentList =
    isVersionSupported &&
    instance.enableDataAgent &&
    showDataAgent &&
    isDataAgentToggleFeatureEnabled;

  return (
    <div className="ClusterEdit cluster-data-agent-edit">
      <div className="ClusterEdit__icon">
        <Icon component={ShieldIcon} />
      </div>
      <section className="cluster-data-agent-edit__body">
        <div className="ClusterEdit__content cluster-data-agent-edit__content">
          <div className="cluster-data-agent-edit__main">
            <h3 className="ClusterEdit__title">Enable Data Agent</h3>
            <div className="cluster-data-agent-edit__body">
              {instance.enableDataAgent ? (
                <div className="cluster-data-agent-edit__body-enabled">
                  <p className="cluster-data-agent-edit__hint">
                    Data agent allow Incorta to connect to on-premise data
                    sources, which may be protected behind a firewall that
                    blocks Incorta from connecting to the data source directly.
                  </p>
                  <p className="cluster-data-agent-edit__hint">
                    Follow the following{' '}
                    <button className="link" onClick={() => setShowModal(true)}>
                      steps
                    </button>{' '}
                    to setup data agent. For more information, visit the{' '}
                    <a
                      href="https://docs.incorta.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      documentation
                    </a>{' '}
                    on Data Agent.
                  </p>
                  <p className="cluster-data-agent-edit__hint">
                    Your data agent agent directory auto downloaded to your
                    machine, or download from the following link{' '}
                    <a
                      download
                      href={downloadLink}
                      className="download-link link"
                    >
                      <DownloadOutlined /> Download Data Agent
                    </a>
                  </p>
                </div>
              ) : (
                <div>
                  <p className="cluster-data-agent-edit__hint">
                    <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.hint" />
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="cluster-data-agent-edit__switch-button">
            {canUpdate && (
              <Tooltip
                title={
                  !isDataAgentToggleFeatureEnabled ? (
                    'Data Agent has been temporarily disabled.'
                  ) : !enable ? (
                    'Your cluster must be connected to apply this change'
                  ) : !showDataAgent ? (
                    <FormattedMessage
                      id="clusterConfiguration.general.actionNotSupportedInCurrentPlan.message"
                      values={{
                        action: intl.formatMessage({
                          id: 'clusterConfiguration.general.actionNotSupportedInCurrentPlan.dataAgent',
                        }),
                      }}
                    />
                  ) : (
                    ''
                  )
                }
              >
                <Switch
                  disabled={
                    !enable ||
                    !isDataAgentToggleFeatureEnabled ||
                    !showDataAgent
                  }
                  onChange={handleChange}
                  loading={isLoading}
                  checked={instance.enableDataAgent}
                />
              </Tooltip>
            )}
          </div>
        </div>

        {showDataAgentList ? (
          isClusterRunning ? (
            <DataAgentConnections
              instanceID={instance.id}
              clusterName={instance.name}
            />
          ) : (
            <DisconnectedClusterPlaceholder
              title={
                <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.disconnectedComponent.title" />
              }
              description={
                <FormattedMessage id="clusterConfiguration.configurationsTab.dataAgent.dataAgentConnectionsTable.disconnectedComponent.description" />
              }
            />
          )
        ) : null}
      </section>

      <Modal
        visible={showModal}
        title={'Enable Data Agent'}
        onCancel={() => setShowModal(false)}
        width={800}
        centered
        footer={null}
      >
        <DataAgentSetupStepsModalContent
          downloadLink={downloadLink}
          dataAgentNewUI={instance.dataAgentNewUI}
          analyticsDataAgent={instance.analyticsDataAgent}
          loaderDataAgent={instance.loaderDataAgent}
          cmcDataAgent={instance.cmcDataAgent}
        />
      </Modal>
    </div>
  );
}

interface UseDownloadLinkArg {
  instance: Instance;
}
function useDownloadLink({ instance }: UseDownloadLinkArg) {
  let instanceid = instance.id;
  let link = `${API_URL}/users/instances/downloadagent?instanceid=${instanceid}`;

  return link;
}

export default ClusterDataAgentEdit;
