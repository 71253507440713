import { Result } from 'antd';
import React from 'react';

type ErrorResultTableProps = {
  subTitle: string | JSX.Element;
  extra: JSX.Element[];
};
function ErrorResultTable({ subTitle, extra }: ErrorResultTableProps) {
  return <Result status="error" subTitle={subTitle} extra={extra} />;
}

export default ErrorResultTable;
