import './ClusterDetailsBody.less';

import React, { useContext, useMemo } from 'react';
import { Spin, Tabs } from 'antd';
import { useHistory, useLocation } from 'react-router';
import { parse } from 'query-string';
import LibrariesContainer from './Libraries/LibrariesContainer';
import ClusterServices from 'components/ClusterDetails/ClusterServices/ClusterServices';
import MainDetails from 'components/ClusterDetails/ClusterDetailsBody/MainDetails/MainDetails';
import Configurations from 'components/ClusterDetails/ClusterDetailsBody/Configurations/Configurations';
import ClusterBlueprints from 'components/ClusterDetails/ClusterDetailsBody/MainDetails/ClusterBlueprints/ClusterBlueprints';
import SubCluster from 'components/ClusterDetails/ClusterDetailsBody/SubCluster/SubCluster';
import AccessSecurity from 'components/ClusterDetails/ClusterDetailsBody/AccessSecurity/AccessSecurity';
import { AdvancedConfigurations } from 'components/ClusterDetails/ClusterDetailsBody/AdvancedConfigurations/AdvancedConfigurations';
import ScalingConfigurations from 'components/ClusterDetails/ClusterDetailsBody/ScalingConfigurations/ScalingConfigurations';
import {
  Service,
  Instance,
  ServerConfiguration,
  clusterScalingNode,
} from 'types/cluster';
import UnderMaintenance from 'components/SomethingWentWrong/SomethingWentWrong';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import { SessionContext } from 'auth/SessionProvider';
import useToggleFeature, { TOGGLE_FEATURE_KEYS } from 'hooks/useToggleFeature';
import ClusterScheduling from 'components/ClusterDetails/ClusterDetailsBody/ClusterScheduling/ClusterScheduling';
import { InstancePlatform } from 'utils/platforms';
import usePlatformActionsSupport, {
  PLATFORM_ACTIONS_KEYS,
} from 'hooks/usePlatformActionsSupport';

const { TabPane } = Tabs;

interface ClusterDetailsBodyProps {
  instance: Instance;
  services?: Service[];
  serverConfiguration?: ServerConfiguration[];
  incortaXServerConfiguration?: ServerConfiguration[];
  mlflowServerConfiguration?: ServerConfiguration[];
  isPending: boolean;
  isRejected: boolean;
}

function ClusterDetailsBody({
  instance,
  services,
  serverConfiguration,
  incortaXServerConfiguration,
  mlflowServerConfiguration,
  isPending,
  isRejected,
}: ClusterDetailsBodyProps) {
  const { user, isLoading, isOnProduction } = useContext(SessionContext);
  const history = useHistory();
  const isTrialUser = user?.role?.id === 1;
  const showLibrariesTab = !isOnProduction || (isOnProduction && !isTrialUser);
  const { isPlatformActionSupported: isLibrariesPlatformActionSupported } =
    usePlatformActionsSupport({
      currentPlatform: instance!.platform,
      userPlatformActions: user?.platformActions,
      platformKey: PLATFORM_ACTIONS_KEYS.packagesInstallion,
    });

  const { isToggleFeatureEnabled: accessAndSecurityIsEnabled } =
    useToggleFeature({
      user,
      featureKey: TOGGLE_FEATURE_KEYS.whitelist,
    });
  const { isToggleFeatureEnabled: isSubClusterToggleFeatureEnabled } =
    useToggleFeature({
      user,
      featureKey: TOGGLE_FEATURE_KEYS.subcluster,
    });

  const { isToggleFeatureEnabled: isSchedularFeatureEnabled } =
    useToggleFeature({
      user,
      featureKey: TOGGLE_FEATURE_KEYS.schedular,
    });

  const { isToggleFeatureEnabled: showClusterScaling } = useToggleFeature({
    user,
    featureKey: TOGGLE_FEATURE_KEYS.autoScale,
  });
  const isUserAuthorizedToViewSchedulingTab = instance?.authorizedRoles?.some(
    role => role === 'owner' || role === 'accountAdmin',
  );
  const isClusterAzure = [
    InstancePlatform.AZURE,
    InstancePlatform.AZURE_SAAS,
  ].includes(instance?.platform as InstancePlatform);
  const showSchedulingTab =
    isUserAuthorizedToViewSchedulingTab && !isClusterAzure;

  const clusterScalingNodes = instance?.clusterScalingNodes;
  const { analyticsClusterScalingNodes, loaderClusterScalingNodes } =
    groupClusterScalingNodesByServices();

  const memoizedAnalyticsClusterScalingNodes = useMemo(
    () => analyticsClusterScalingNodes,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const memoizedLoaderClusterScalingNodes = useMemo(
    () => loaderClusterScalingNodes,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  let { search } = useLocation();
  let { tab = 'main' } = parse(search);

  if (isPending && !instance) {
    return (
      <div className="cluster-details-body">
        <Spin tip="Getting your cluster details" />
      </div>
    );
  }

  if (isRejected && !instance) {
    return (
      <div className="cluster-details-body flex-center">
        <UnderMaintenance />
      </div>
    );
  }

  let showSubCluster =
    instance?.clusterPolicy.subClusterPolicy.includes('list') &&
    isSubClusterToggleFeatureEnabled;
  let showAccessAndSecurity =
    instance?.clusterPolicy.userPolicy.includes('list');
  let showConfiguration =
    instance?.clusterPolicy.managementPolicy.includes('list');
  let showBlueprint = instance?.clusterPolicy.blueprintPolicy.includes('list');
  const showAdvancedConfigs = [
    'owner',
    'accountAdmin',
    'accountManager',
  ].includes(instance?.clusterPolicy.roleName!);

  function groupClusterScalingNodesByServices() {
    const analyticsClusterScalingNodes: clusterScalingNode[] = [];
    const loaderClusterScalingNodes: clusterScalingNode[] = [];
    clusterScalingNodes?.forEach(node => {
      if (node.service === 'analytics') {
        analyticsClusterScalingNodes.push(node);
      } else if (node.service === 'loader') {
        loaderClusterScalingNodes.push(node);
      }
    });
    return { analyticsClusterScalingNodes, loaderClusterScalingNodes };
  }

  if (isLoading) return <FullPageLoader />;

  return (
    <div className="ClusterDetailsBody">
      {services && instance && (
        <ClusterServices instance={instance} services={services} />
      )}
      {instance &&
        services &&
        serverConfiguration &&
        incortaXServerConfiguration &&
        mlflowServerConfiguration && (
          <Tabs
            destroyInactiveTabPane
            activeKey={tab as string}
            onChange={key =>
              history.push({
                pathname: `/clusters/${instance?.name}`,
                search: `?tab=${key}`,
              })
            }
            className="tabs"
          >
            <TabPane tab="Main" key="main">
              <MainDetails
                instance={instance}
                services={services}
                serverConfiguration={serverConfiguration}
                incortaXServerConfiguration={incortaXServerConfiguration}
              />
            </TabPane>

            {showConfiguration && (
              <TabPane tab="Configurations" key="configurations">
                <Configurations
                  instance={instance}
                  services={services}
                  serverConfiguration={serverConfiguration}
                  incortaXServerConfiguration={incortaXServerConfiguration}
                  mlflowServerConfiguration={mlflowServerConfiguration}
                />
              </TabPane>
            )}
            {showBlueprint && (
              <TabPane tab="Data Apps" key="data-apps">
                <ClusterBlueprints instance={instance} services={services} />
              </TabPane>
            )}
            {showAccessAndSecurity && accessAndSecurityIsEnabled && (
              <TabPane tab={'Access & Security'} key="access-and-security">
                <AccessSecurity instance={instance} user={user} />
              </TabPane>
            )}
            {showSubCluster && (
              <TabPane tab="Self-Managed Clusters" key="self-managed-clusters">
                <SubCluster
                  instance={instance}
                  service={services[0]}
                  user={user}
                />
              </TabPane>
            )}
            {showLibrariesTab && user && user.platformActions && (
              <TabPane tab="Libraries" key="libraries">
                <LibrariesContainer
                  instanceID={instance.id}
                  isClusterRunning={instance.status === 'running'}
                  hasClusterChidoriEnabled={instance.isChidori}
                  canUpdate={instance.clusterPolicy.clusterPolicy.includes(
                    'update',
                  )}
                  isLibrariesPlatformActionSupported={
                    !!isLibrariesPlatformActionSupported
                  }
                />
              </TabPane>
            )}
            {showAdvancedConfigs && (
              <TabPane
                tab="Advanced Configurations"
                key="advanced-configurations"
              >
                <AdvancedConfigurations
                  instance={instance}
                  services={services}
                />
              </TabPane>
            )}
            {showClusterScaling && instance.clusterScalingNodes && (
              <TabPane
                tab="Scaling Configurations"
                key="scaling-configurations"
              >
                <ScalingConfigurations
                  instanceID={instance.id}
                  isInstanceRunning={instance.status === 'running'}
                  autoScaleStatus={instance.autoscale}
                  analyticsClusterScalingNodes={
                    memoizedAnalyticsClusterScalingNodes
                  }
                  loaderClusterScalingNodes={memoizedLoaderClusterScalingNodes}
                />
              </TabPane>
            )}
            {showSchedulingTab && isSchedularFeatureEnabled && (
              <TabPane tab="Scheduling" key="scheduling">
                <ClusterScheduling instanceID={instance.id} />
              </TabPane>
            )}
          </Tabs>
        )}
    </div>
  );
}

export default ClusterDetailsBody;
