import axios from 'axios';
import {
  CreateDataAgentConnectionResult,
  DataAgentConnectionAction,
  DataAgentConnectionsFilterPayload,
  DataAgentConnectionsResult,
  DeleteDataAgentConnectionResult,
  ExecuteActionOnDataAgentConnectionResult,
  GenerateDataAgentConnectionAuthenticationFileResult,
  UpdateDataAgentConnectionResult,
} from 'types/dataAgent.types';
import { API_URL } from 'utils/http';

export async function dataAgentConnections({
  instanceID,
  page,
  limit = 10,
  filters,
}: {
  instanceID: string;
  page: number;
  limit: number;
  filters?: DataAgentConnectionsFilterPayload[];
}) {
  return axios.get<DataAgentConnectionsResult>(
    `${API_URL}/users/instances/${instanceID}/da/list`,
    {
      params: {
        limit: limit,
        offset: limit * page,
        ...(filters
          ? {
              filters: JSON.stringify(filters),
            }
          : {}),
      },
    },
  );
}

export async function createDataAgentConnection({
  instanceID,
  tenantID,
  name,
  description = '',
}: {
  instanceID: string;
  tenantID: number;
  name: string;
  description?: string;
}) {
  return axios.post<CreateDataAgentConnectionResult>(
    `${API_URL}/users/instances/${instanceID}/da/create`,
    {
      tenantID,
      name,
      description,
    },
  );
}

export async function updateDataAgentConnection({
  instanceID,
  dataAgentID,
  description,
}: {
  instanceID: string;
  dataAgentID: number;
  description: string;
}) {
  return axios.put<UpdateDataAgentConnectionResult>(
    `${API_URL}/users/instances/${instanceID}/da/${dataAgentID}/update`,
    {
      description,
    },
  );
}

export async function deleteDataAgentConnection({
  instanceID,
  dataAgentConnectionIDs,
}: {
  instanceID: string;
  dataAgentConnectionIDs: number[];
}) {
  return axios.delete<DeleteDataAgentConnectionResult>(
    `${API_URL}/users/instances/${instanceID}/da/delete`,
    {
      data: {
        ids: dataAgentConnectionIDs,
      },
    },
  );
}

export async function generateDataAgentConnectionAuthenticationFile({
  instanceID,
  dataAgentID,
  tenantName,
}: {
  instanceID: string;
  dataAgentID: number;
  tenantName: string;
}) {
  return axios.post<GenerateDataAgentConnectionAuthenticationFileResult>(
    `${API_URL}/users/instances/${instanceID}/da/${dataAgentID}/generate-auth-file`,
    {
      clientOrigin: window.location.origin, // Frontend Domain (Can be localhost)
      tenantName,
    },
  );
}

export async function executeActionOnDataAgentConnection({
  instanceID,
  dataAgentID,
  tenantName,
  action,
}: {
  instanceID: string;
  dataAgentID: number;
  tenantName: string;
  action: DataAgentConnectionAction;
}) {
  return axios.post<ExecuteActionOnDataAgentConnectionResult>(
    `${API_URL}/users/instances/${instanceID}/da/${dataAgentID}/execute-action`,
    {
      tenantName,
      action,
    },
  );
}
