import './SubClusterCard.less';

import React from 'react';
import Icon from '@ant-design/icons';
import { useHistory } from 'react-router';
import MainCard from '../MainCard/MainCard';
import SubClusterList from '../../SubCluster/SubClusterList/SubClusterList';
import { ReactComponent as AnalyticsIcon } from 'images/analytics.svg';
import { Instance } from 'types/cluster';
// import { sum } from 'utils';

interface SubClusterCardProps {
  instance: Instance;
}
function SubClusterCard({ instance }: SubClusterCardProps) {
  let history = useHistory();

  // let subClustersLength = instance.subClusters.length;
  // let subClustersNodesLength = sum(
  //   instance.subClusters,
  //   (subCluster: any) => subCluster.nodes,
  // );

  let canCreate = instance.clusterPolicy.subClusterPolicy.includes('create');
  let canUpdate = instance.clusterPolicy.subClusterPolicy.includes('update');

  // let incortaUnitsSum = sum(
  //   instance.subClusters,
  //   sub => (sub.ipu / 2) * sub.nodes,
  // );

  return (
    <MainCard
      icon={<Icon component={AnalyticsIcon} />}
      title={'Self-Managed Cluster'}
      subtitle={
        <>
          {/* {subClustersLength} Analytics Subclusters ({subClustersNodesLength}{' '}
          Analytics services - {incortaUnitsSum} IU) */}
        </>
      }
      hideFooter={!(canCreate || canUpdate)}
      footerText={'Manage'}
      onFooterClick={() => {
        history.push(`/clusters/${instance.name}?tab=self-managed-clusters`);
      }}
      content={
        <div className="SubClusterCard">
          <p className="SubClusterCard__hint">
            Create Self-Managed Clusters for dedicated groups of users (who
            might have different priorities) within your cluster to isolate work
            environment.
          </p>

          <div className="SubClusterCard__content">
            <SubClusterList instance={instance} minimum />
          </div>
        </div>
      }
    />
  );
}

export default SubClusterCard;
