import axios from 'axios';
import { security } from './security';

export const API_URL = process.env.REACT_APP_API_URL;

/**
 * add token to headers
 */
axios.interceptors.request.use(async config => {
  try {
    const token = await security.getAccessTokenSilently()({
      audience: process.env.REACT_APP_AUTH_AUDIENCE,
    });
    config.headers['Authorization'] = `Bearer ${token}`;
  } catch (e) {
    console.log(e);
  }

  return config;
});

/**
 * logout user if token is invalid
 */
const unAuthUrls = ['/me'];

axios.interceptors.response.use(
  res => {
    return res;
  },
  err => {
    console.dir(err);

    if (err?.response?.status !== 403) {
      return Promise.reject(err);
    }

    for (const url of unAuthUrls) {
      if (err?.config?.url?.endsWith?.(url)) {
        return Promise.reject(err);
      }
    }

    return Promise.reject(err);
  },
);

export function getContentDispositionFilename(disposition: string) {
  let filename = '';
  var matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
  if (matches != null && matches[1]) {
    filename = matches[1].replace(/['"]/g, '');
  }

  return filename;
}

export function getFileNameFromBlobContentDisposition({
  contentDisposition,
  defaultFileName,
}: {
  contentDisposition: string;
  defaultFileName: string;
}) {
  let filename = defaultFileName;
  if (contentDisposition) {
    const match = contentDisposition.match(/filename\*?=['"]?([^;]*)['"]?/);
    if (match && match[1]) {
      filename = decodeURIComponent(match[1].replace("UTF-8''", ''));
    }
  }
  return filename;
}
