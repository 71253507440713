import { Tag } from 'antd';
import React from 'react';
import { ServiceStatus } from 'types/cluster';
import { statusTag } from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterServicesControl/ClusterServicesControlHelper/useClusterServicesControlHelper';
import { statusTagGreyInfo } from 'utils/cluster';

type StatusTagProps = {
  statusName: Map<ServiceStatus, string>;
  status: ServiceStatus;
};
function ClusterServicesControlStatusTag({
  statusName,
  status,
}: StatusTagProps) {
  return (
    <Tag
      className="status-tag"
      icon={statusTag[status]?.icon ?? statusTagGreyInfo.icon}
      color={statusTag[status]?.color ?? statusTagGreyInfo.color}
      key={status}
    >
      {statusName.get(status) ?? status}
    </Tag>
  );
}

export default ClusterServicesControlStatusTag;
