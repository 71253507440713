import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { notification, message } from 'antd';

export function displaySuccessMessage(message: string) {
  notification.success({
    message,
    icon: <CheckCircleOutlined style={{ color: '#37517F' }} />,
    style: { fontSize: 16, color: '#21314D' },
  });
}

export function displaySuccessAntdMessage(messageText: string) {
  message.success(messageText);
}

export function displayErrorAntdMessage(messageText: string) {
  message.error(messageText);
}

export function wait(time: number) {
  return new Promise(resolve => setTimeout(resolve, time));
}

export function searchFilterOption(input: any, option: any) {
  if (typeof option.props?.children === 'string') {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  }
  return false;
}

export function sum<T>(array: T[], cb: (a: T) => number): number {
  let sum = array.reduce((acc, a) => acc + cb(a), 0);
  return round(sum);
}

function round(number: number) {
  return Math.round((number + Number.EPSILON) * 100000) / 100000;
}

export function search(text: string | null, searchKeyword: string) {
  if (!text) {
    return false;
  }
  return (
    text
      .trim()
      .replace(/\s/g, '')
      .toLowerCase()
      .indexOf(searchKeyword.trim().replace(/\s/g, '').toLowerCase()) > -1
  );
}

export function getCookie(cname: string) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}
